import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';

import mailIcon from "../../images/icon-mail.svg";
import mailInput from "../../images/icon-mail.svg";
import phoneInput from "../../images/icon-phone.svg";

function ContactPageContent(props) {

    const { contactpageData, setUpdatedContactpageData, validationContactpageData, setValidationContactpageData, setContentChange, setHasUnsavedChanges,setShowUpgradeBtn, userData } = props;
    const [pageText, setPageText] = useState( contactpageData?.userMeta?.pageText || '' );
    const [publicAddress, setPublicAddress] = useState(contactpageData?.userMeta?.publicAddress || '');
    const [publicEmail, setPublicEmail] = useState(contactpageData?.userMeta?.publicEmail || contactpageData.email );
    const [phoneNumber, setPhoneNumber] = useState(contactpageData?.userMeta?.phoneNumber || contactpageData.phoneNumber );
    const [showSocialNetwork, setShowSocialNetwork] = useState(contactpageData?.userMeta?.showSocialNetwork || false);
    const [showContactForm, setShowContactForm] = useState(contactpageData?.userMeta?.showContactForm || false);
    const [toReceiveEmail, setToReceiveEmail] = useState(contactpageData?.userMeta?.toReceiveEmail || contactpageData.email);

    const [isPublicEmailValid, setPublicEmailIsValid] = useState(validationContactpageData.publicEmailValid);
    const [isToReceiveEmailValid, setToReceiveEmailIsValid] = useState(validationContactpageData.receiveEmailValid);
    const [isPublicaPhoneValid, setPublicaPhoneValid] = useState(validationContactpageData.publicaPhoneValid)

    const pageTextRef = useRef(null);
    const publicAddresstRef = useRef(null);

    let contactPageData = contactpageData?.userMeta;

    // Check if summary is undefined or not availabdeleteIconle
    if (contactPageData === undefined || contactPageData === null) {
        contactPageData = {}; // Set summary as blank
    }

    useEffect(() => {
        setPageText(contactpageData?.userMeta?.pageText || '');
        setPublicAddress(contactpageData?.userMeta?.publicAddress || '');
        setPublicEmail(contactpageData?.userMeta?.publicEmail || contactpageData.email);
        setPhoneNumber(contactpageData?.userMeta?.phoneNumber || contactpageData.phoneNumber)
        setShowSocialNetwork(contactpageData?.userMeta?.showSocialNetwork || false);
        setShowContactForm(contactpageData?.userMeta?.showContactForm || false)
        setToReceiveEmail(contactpageData?.userMeta?.toReceiveEmail || contactpageData.email)

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            publicEmail: contactpageData?.userMeta?.publicEmail || contactpageData.email // Update receiveEmailValid with the new value
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            toReceiveEmail: contactpageData?.userMeta?.toReceiveEmail || contactpageData.email // Update receiveEmailValid with the new value
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            phoneNumber:contactpageData?.userMeta?.phoneNumber || contactpageData.phoneNumber
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            pageText: contactpageData?.userMeta?.pageText || '' // Update receiveEmailValid with the new value
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            publicAddress: contactpageData?.userMeta?.publicAddress || '' // Update receiveEmailValid with the new value
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            showSocialNetwork: contactpageData?.userMeta?.showSocialNetwork || false // Update receiveEmailValid with the new value
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            showContactForm: contactpageData?.userMeta?.showContactForm || false // Update receiveEmailValid with the new value
        }));


    }, [contactPageData]);

    useEffect(() => {
        const handleResize = () => {
            if( pageTextRef.current ) {
                pageTextRef.current.style.height = '80px';
                var extraSpace = 2;

                if (pageTextRef.current.scrollHeight > 80) {
                    extraSpace = 10;
                }
                pageTextRef.current.style.height = pageTextRef.current.scrollHeight + extraSpace + 'px';
            }
        };

        handleResize();
    
        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);    
    
        // Cleanup: Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, [pageText]);

    useEffect(() => {
        const handleResize = () => {
            if( publicAddresstRef.current ) {
                publicAddresstRef.current.style.height = '40px';
                var extraSpace = 2;

                if (publicAddresstRef.current.scrollHeight > 80) {
                    extraSpace = 10;
                }
                publicAddresstRef.current.style.height = publicAddresstRef.current.scrollHeight + extraSpace + 'px';
            }
        };

        handleResize();
    
        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);
    
    
        // Cleanup: Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, [publicAddress]);

    const handlePublicEmailChange = (e) => {
        const inputValue = e.target.value;
        setPublicEmail(inputValue);
        setContentChange(true)
        setHasUnsavedChanges(true)

        if( inputValue.length > 0 ) {    
            // Basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setPublicEmailIsValid(emailRegex.test(inputValue));
    
            setValidationContactpageData((prevState) => ({
                ...prevState, // Spread the previous state
                publicEmailValid: emailRegex.test(inputValue) // Update publicEmailValid with the new value
            }));
        } else {
            setPublicEmailIsValid(false);

            setValidationContactpageData((prevState) => ({
                ...prevState, // Spread the previous state
                showError: false // Update publicEmailValid with the new value
            }));
        }

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            publicEmail: e.target.value // Update receiveEmailValid with the new value
        }));

        validationContactpageData.showError = false;
    }

    const handleToReceiveEmailChange = (e) => {
        const inputValue = e.target.value;
        setToReceiveEmail(inputValue);
    
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setToReceiveEmailIsValid(emailRegex.test(inputValue));

        setValidationContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            receiveEmailValid: emailRegex.test(inputValue) // Update receiveEmailValid with the new value
        }));

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            toReceiveEmail: e.target.value // Update receiveEmailValid with the new value
        }));

        validationContactpageData.showError = false;
    }

    const handlePhoneOnChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber(inputValue);
        setContentChange(true);
        const phonePattern = /^[0-9()+\-#]{10,15}$/;
        if (inputValue.length > 0) {
            const isValid = phonePattern.test(inputValue);
            setPublicaPhoneValid(isValid);
            setValidationContactpageData((prevState) => ({
                ...prevState, 
                publicaPhoneValid: isValid,
                showError: true 
            }));
        } else {
            setPublicaPhoneValid(false);
            setValidationContactpageData((prevState) => ({
                ...prevState, 
                showError: false,
                publicaPhoneValid: true, 
            }));
        }
        setUpdatedContactpageData((prevState) => ({
            ...prevState, 
            phoneNumber: inputValue 
        }));
        validationContactpageData.showError = false;
    }

    const handlePageTextInput = (e) => {
        setContentChange(true)
        setHasUnsavedChanges(true)
        e.target.style.height = '80px';
        var extraSpace = 2;

        if (e.target.scrollHeight > 80) {
            extraSpace = 10;
        }
        e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

        setPageText(e.target.value); 

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            pageText: e.target.value // Update receiveEmailValid with the new value
        }));
    }

    const handlePageAddresstInput = (e) => {
        setContentChange(true)
        setHasUnsavedChanges(true)
        e.target.style.height = '40px';
        var extraSpace = 2;

        if (e.target.scrollHeight > 80) {
            extraSpace = 10;
        }
        e.target.style.height = e.target.scrollHeight + extraSpace + 'px';

        setPublicAddress(e.target.value);   

        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            publicAddress: e.target.value // Update receiveEmailValid with the new value
        }));
    }

    const handleSocialCheckbox = (e) => {
        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            showSocialNetwork: ! showSocialNetwork // Update receiveEmailValid with the new value
        }));

        setShowSocialNetwork( ! showSocialNetwork );
    }

    const handleContactFormCheckbox = (e) => {
        setUpdatedContactpageData((prevState) => ({
            ...prevState, // Spread the previous state
            showContactForm: ! showContactForm // Update receiveEmailValid with the new value
        }));

        setShowContactForm( ! showContactForm );
    }

    useEffect(() => {
        const homepageContentElement = document.querySelector('.contactpage-content');
    
        if (homepageContentElement) {
          document.body.classList.add('contact-publication');
        }

        return () => {
          document.body.classList.remove('contact-publication');
        };
    }, []);

    const handleKeyPress = (e) => {
        const allowedKeys = /[0-9+()\-\b#]/; 
        if (!allowedKeys.test(e.key)) {
            e.preventDefault();
        }
    }

    return (
        <div className="newpageLeftRow textInfoBlock contactpage-content">
            <h2>Contact</h2>
            <p>You can display all your contact details on a single page. This page not only showcases your contact information but also allows you to add a personalized touch with a short introductory text.</p>
            <p>With the built-in contact form, your visitors can easily send messages and inquiries directly through a form, which are then conveniently forwarded to your email address. This direct line of communication enhances the user experience by simplifying the process of reaching out, making your contact page not just informative, but also interactive.</p>

            <div className="newpageCard contactpageCard">

                <div className="newpageLeftRow">
                    <div className="linkData newpagefile">
                        <p className="label fieldsLabel" >Page text (optional)</p>
                        <textarea
                            id="pageText" 
                            placeholder="Enter text to be displayed before your contact details..." 
                            className="pageText linkDataDiscripation contact-page-text"
                            ref={pageTextRef}
                            onChange={(e) => {
                                handlePageTextInput(e)
                            }} 
                            value={pageText}
                        ></textarea>
                    </div>
                </div>

                <div className="newpageLeftRow">
                    <div className="linkData">
                        <p className="label fieldsLabel" >Public address (optional)</p>
                        <textarea 
                            id="publicAddress" 
                            className="publicAddress linkDataTitle contact-page-text" 
                            placeholder="Enter your public address" 
                            ref={publicAddresstRef}
                            value={publicAddress}
                            onChange={(e) => {
                            handlePageAddresstInput(e)
                        }} 
                        ></textarea>
                    </div>
                </div>

                <div className="contactpageWrap formControlInputs">
                    <div className="newpageLeftRow textBlock formControlFields">
                        <p className="label fieldsLabel">Public email (optional)</p>
                        <div className="adressInputBlock inputFieldsIcon">
                            <img className="adressLink adressLinkLeftInput" src={mailInput} alt="Link" />
                            <input 
                                className="urlInputLeft mailInputBox publicMailInputbox publicinputBox" 
                                type="text" 
                                value={publicEmail}
                                placeholder="Enter your public email address"
                                autocomplete="off"
                                onChange={handlePublicEmailChange}  />
                            { ! isPublicEmailValid && validationContactpageData.showError && (
                                <p className="validateError">Enter a valid email address</p>
                            )}
                        </div>
                    </div>
                    <div className="newpageLeftRow textBlock formControlFields">
                        <p className="label fieldsLabel">Public phone number (optional)</p>
                        <div className="adressInputBlock inputFieldsIcon">
                            <img className="adressLink adressLinkLeftInput" src={phoneInput} alt="Link" />
                            <input 
                                className="urlInputLeft mailInputBox publicMailInputbox publicinputBox" 
                                type="text" 
                                value={phoneNumber}
                                placeholder="Enter your public phone number"
                                autocomplete="off"
                                onChange={handlePhoneOnChange}
                                onKeyPress={handleKeyPress}   />
                            {!isPublicaPhoneValid && validationContactpageData.showError && (
                                <p className="validateError">Enter a valid phone number</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="display-form-group">
                    {(userData?.planDetails?.plan === "free_plan"  || userData?.planDetails?.plan === "basic_plan") ? 
                        <div className="display-form-item">
                            <p>
                                Contact form
                                <span>Built-in contact form to directly reach out to you with inquiries and messages.</span>
                            </p>
                            <button className="btn-upgrade contact-upgrade-button primary-button button minwidthAuto" onClick={() => { setShowUpgradeBtn(true) }} >Upgrade</button>
                        </div>
                        :
                        <div className="display-form-item paid-plan-form">                          
                            <p>
                                Contact form
                                <span>Built-in contact form to directly reach out to you with inquiries and messages.</span>
                            </p>

                            <div className="contentDrop more-contact-detail paid-user-detailBox">
                                <div className="list-search page-contact-list">
                                    <label>
                                        <input type="checkbox" checked={showContactForm} onChange={(e) => handleContactFormCheckbox(e)} />
                                        <p>Display contact form</p>
                                    </label>
                                </div>
                                <div className="newpageLeftRow textBlock">
                                    <p className="label fieldsLabel">Email to receive messages</p>
                                    <div className="adressInputBlock contact-inputBlock">
                                        <img className="adressLink adressLinkLeftInput" src={mailInput} alt="Link" />
                                        <input
                                            className="urlInputLeft mailInputBox publicMailInputbox publicinputBox"
                                            type="text"
                                            placeholder="Email to receive messages"
                                            value={toReceiveEmail}
                                            onChange={handleToReceiveEmailChange}
                                        />
                                        { ! isToReceiveEmailValid && validationContactpageData.showError && (
                                            <p className="validateError">Enter a valid email address</p>
                                        )}
                                    </div>
                                </div>
                                <div className="newpagenotes">
                                    <p>
                                        <strong>Note:</strong> This email address is not visible to your visitors.
                                    </p> 
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        
    )
}

export default ContactPageContent;