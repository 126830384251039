import React, { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

import UserPlanActionDropdown from "./UserPlanActionDropdown";
import DeleteUser from "./DeleteUser";

import {formattedDate, getCountryName, getCountryWithFlag, isUserAdmin} from "../../utils/functions";

import avatar from "../../images/profile-user.svg";
import search from "../../images/search.svg";
import clear from "../../images/delete.svg";
import penIcon from "../../images/pen-img.svg";
import Delete from "../../images/delete.svg";
import iconDropDown from "../../images/icondropdown.svg";
import moreIcon from "../../images/more.svg";
import { DataTableList } from "../../components/DataTable/DataTableList";
import NoResultsTeam from "../Team/NoResultsTeam";


const UsersTable = () => {
    const ref = useRef();
    const modalRef = useRef();
    const usercountryRef = useRef();
    const tableActionRef = useRef();

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [isOpen, setOpen] = useState(false);

    const [userData, setUserData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [originalData, setOriginalData] = useState(userList);

    const [userCountrySelect, setUserCountrySelect] = useState(false)
    const [countryList, setCountryList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');

    const [userPlanSelect, setUserPlanSelect] = useState(false);
    const [userPlanList, setUserPlanList] = useState([]);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [readFields, setReadFields] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [userPlanData, setUserPlanData] = useState([])
    const [columnWidths, setColumnWidths] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const userPlan = ["Free", 'Unlimited', 'Personal Assistant']
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setUserPlanSelect(false);
            }

            if (usercountryRef.current && !usercountryRef.current.contains(event.target)) {
                setUserCountrySelect(false)
            }

            if (tableActionRef.current && !tableActionRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getcountries?type=customer`);

                if( response.status == 200 ) {
                    setCountryList(response.data.result || [])
                }
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData(); // Call the async function immediately
    }, []);



    const abortControllerRef = useRef(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            abortControllerRef.current = new AbortController();
            const search = searchInput.toLowerCase();
            const countries = selectedCountryCode.length > 0 ? selectedCountryCode.join(',') : '';
            const changePlanName = userPlanData.map((plan) => {
                switch (plan) {
                  case 'Free':
                    return 'free_plan,basic_plan';
                  case 'Unlimited':
                    return 'unlimited_plan';
                  case 'Personal Assistant':
                    return 'personal_assistant_plan';
                  default:
                    return plan;
                }
              }).join(',');
            try {
                setIsLoading(true);
                setIsLoadingData(true)
                let response;
                if (search === "") {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getusers/?page=${currentPage}&search=${search}&countries=${countries}&planName=${changePlanName}`);
                } else {

                    response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getusers/?page=${currentPage}&search=${search}&countries=${countries}&planName=${changePlanName}`, {
                        signal: abortControllerRef.current.signal
                    });
                }
                if (response.status == 200) {
                    const usersArray = response.data.users.map(item => ({
                        ...item,
                        countryName: getCountryName(item?.country || ''),
                    }));
                    if(usersArray?.length <= 0){
                        setIsLoadingData(false)
                    }
                    setTotalPage(response.data.totalPages || 1);
                    setOriginalData(usersArray);
                    setUserList(usersArray);
                    setIsLoading(false);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        };

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setDebounceTimeout(
            setTimeout(() => {
                fetchData();
            }, 1000) 
        );

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [searchInput, currentPage, selectedCountryCode, userPlanData]);

    useEffect(() => {
        if (originalData !== undefined) {
            const uniqueCountries = [];
            const uniquePlans = [];
            originalData.forEach((element) => {
                if (!uniqueCountries.includes(element.country)) {
                    uniqueCountries.push(element.country);
                }
                if (!uniquePlans.includes(element.plan)) {
                    uniquePlans.push(element.plan);
                }
            });
            setUserPlanList(uniquePlans);
            setUserList(originalData);
        } else {
            setUserPlanSelect(false);
            setUserPlanList([]);
            setUserList([]);
        }
    }, [originalData]);

    const onClose = (e) => {
        setOpen(false);
        document.body.classList.remove('hidden-message');
    };

     useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths().then(() => {
                setTimeout(() => {
                    setIsLoadingData(false);
                }, 1000);                
            });
        }
    }, [originalData]);

    const calculateColumnWidths = () => {
        return new Promise((resolve) => {
            const tempWidths = originalData?.reduce((acc, row) => {
                Object.keys(row).forEach((key) => {
                    const dataValue = row[key].toString();
                    const title = row[key];
                    let maxContentLength = 0;
                    if (key === 'country') {
                        const countryFlagContent = getCountryName(row?.country);
                        maxContentLength = Math.max(countryFlagContent.length + 1.5); // Add extra padding for flag content
                    } else if(key === 'planDetail') {
                        const planName = (row?.planDetail?.plan === "free_plan" || row?.planDetail?.plan === "basic_plan" || row?.planDetail === "FREE") ? "Free" : (row?.planDetail?.plan === "unlimited_plan") ? "Unlimited" : "Personal Assistant"
                        if(planName === 'Personal Assistant'){
                            maxContentLength = Math.max(planName.length - 7)
                        } else {
                            maxContentLength = Math.max(planName.length - 1);
                        }
                    } 
                    else {
                        maxContentLength = Math.max(dataValue.length, title.length);
                    }
                    if (!acc[key] || maxContentLength > acc[key]) {
                        acc[key] = maxContentLength;
                    }
                });
                return acc;
            }, {});
            if(tempWidths !== undefined){
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    
                        acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    return acc;
                }, {});

                setColumnWidths(widthMap); // Set the calculated column widths
            }
            resolve(); // Resolve the promise once widths are set
        });
    };

    const handleDeleteActionEvent = (item) => {
        if (item?.length != 0 || selectedRows?.length != 0) {
            setOpen(true);
        }
        document.body.classList.add('hidden-message');
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupitem &&  (event.target.closest('.table-action') === null && event.target.closest('.table-data-dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupitem,togglePopup]);

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.displayName.toString().toLowerCase();
        const titleB = rowB?.displayName.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    const columns = [
        {
            name: 'Full Name',
            selector: (row) => row.displayName,
            cell: (row) => {
                return <div className="user-content teamTable">
                            <img src={(row?.profileMedia !== "" && row?.profileMedia !== undefined && row?.profileMedia?.length > 0 ) ? row?.profileMedia : avatar} />
                            <div className="user-brief">
                                <span className="user-name">
                                    <Link to={`/users/${row._id}`}>{row.displayName}</Link>
                                </span>
                                <p className="user-mail">
                                    {row.email}
                                </p>
                            </div>
                        </div>
            },
            sortable: true,
            sortFunction: customTitleSort,
            minWidth: columnWidths['email'] && (parseInt(columnWidths['email']) + 50 > 300) ? '300px' : `${parseInt(columnWidths['email']) + 50}px`,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
        },
        {
            name: 'Country',
            sortable: true,
            width: columnWidths['country'] || '150px',
            selector: (row) => row.country,
            cell: (row) => {
                return <div className="table-country">
                    <span className="country-flag">{getCountryWithFlag(row.country)}</span>
                </div>
            },
            paddingLeft: 0,
            width: columnWidths['country'] || '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
        },
        {
            name: 'Date',
            sortable: true,
            selector: (row) => formattedDate(row.createdAt),
            cell: (row) => {
                return <div className="subscription-date">
                    <span>{formattedDate(row.createdAt)}</span>
                </div>
            },
            paddingLeft: 0,
            width: '160px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", width: '130px', fontSize: "14px" },
        },
        {
            name: 'Plan',
            sortable: true,
            selector: (row) => row?.planDetail?.plan,
            cell: (row) => {
                return <div className="">
                        <span className={`plan-btn ${row?.plan == 'pro' && 'pro-btn'}`}>{`${(row?.planDetail?.plan === "free_plan" || row?.planDetail?.plan === "basic_plan" || row?.planDetail === "FREE") ? "Free" : (row?.planDetail?.plan === "unlimited_plan") ? "Unlimited" : "Personal Assistant"}`}</span>
                    </div>
            },
            paddingLeft: 0,
            width: columnWidths['planDetail'],
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", width: '130px', fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => {
                return (
                    <>
                        <div className="table-action" onClick={() => togglePopup(row)} ref={ref} >
                            <img src={moreIcon} alt="More" />
                        </div>
                        {openPopupitem && openPopupitem._id === row?._id && (
                            <div className="table-data-dropdown" ref={tableActionRef}>
                                <ul>
                                    <li>
                                        { row.status !== "inactive" &&
                                            <span>
                                                <Link className="table-edit-action" to={`/users/${row?._id}`} onClick={handleActionEvent} >
                                                    <img src={penIcon} alt="pen-icon" />
                                                    Edit
                                                </Link>
                                            </span>
                                        }   
                                    </li>
                                    <li>
                                        {isUserAdmin() && (
                                            <span onClick={() => handleDeleteActionEvent(row)} >
                                                <img src={Delete} alt="pen-icon" />
                                                Delete
                                            </span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
            },
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            // headerStyle: { textAlign: 'right' },
        },
    ];

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(originalData.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };


    return (
        <>
            <div className="pagesSearch pageUser">
                <div className="pageSearchTop">
                    <div className="pageSearchInput">
                        <input
                            type="text"
                            id="pages_search"
                            className="inputFields"
                            name="pages_search"
                            placeholder={"Search user member..."}
                            value={searchInput}
                            autoComplete="off"
                            onChange={(e) => {setSearchInput(e.target.value); setCurrentPage(1);}}
                            readOnly={readFields} 
                            onFocus={ () => setReadFields(false) } 
                            onBlur={ () => setReadFields(true) }
                        />
                        <img src={search} alt="Search" />
                    </div>
                    
                    <div ref={usercountryRef} className="select-country dropdown-wrapp">
                        <button className="selectFields" onClick={(e) => setUserCountrySelect(!userCountrySelect)}>
                            All Countries{" "}
                            {selectedCountryCode.length > 0 ? `(${selectedCountryCode.length})` : ""}
                        </button>
                        {
                            userCountrySelect === true && 
                                <div className="dropdown addDropdown countryDropdown">
                                    <ul>
                                        {countryList && countryList.length > 0 && 
                                            countryList.map((country) => (
                                                <li key={country} onClick={() => {
                                                    const result = selectedCountryCode.includes(country)
                                                        ? selectedCountryCode.filter(
                                                            (selectcountry) =>
                                                            selectcountry !== country
                                                        )
                                                        : [...selectedCountryCode, country];
                                                    setSelectedCountryCode(result);
                                                    setCurrentPage(1);
                                                }}>
                                                    <input className="countrySelect" type="checkbox" checked={selectedCountryCode.includes(country)} />
                                                    <span className="country-flag">{getCountryWithFlag(country)}</span>
                                                </li>
                                                
                                            ))
                                        } 
                                    </ul>
                                </div>
                        }
                    </div>

                    <div ref={modalRef} className="userrole-dropdown dropdown-wrapp">
                        <button className="userPlanBtn"
                            onClick={(e) => setUserPlanSelect(!userPlanSelect)}>
                            All Plans{" "}
                            {userPlanData.length > 0
                                ? `(${userPlanData.length})`
                                : ""}
                            <img src={iconDropDown} alt="User Plans" />
                        </button>
                        {userPlanSelect === true && (
                            <UserPlanActionDropdown userPlans={userPlan} selected={userPlanData} changePlan={setUserPlanData} />
                        )}
                    </div>
                </div>
                {isUserAdmin() && (selectedItems.length >= 1) && (
                    <div className="deleteIcon">
                        <img className="deleteIconImage" src={clear} alt="Clear Input" onClick={() => handleDeleteActionEvent([])} />
                    </div>
                )}

            </div>
            <div className={`pagesTable dataTable-list ${(isLoadingData) ? 'loading-list' : ''} `}>
                <DataTableList
                    columns={columns}
                    data={userList}
                    onSelectedRowsChange={({ selectedRows }) => {
                        const selectedIds = selectedRows.map((row) => row._id);
                        setSelectedRows(selectedIds);
                        setIsAllSelected(selectedIds.length === userList.length);
                    }}
                    progressPending={isLoading}
                    progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                    onChangePage={setCurrentPage}
                    paginationTotalRows={totalPage}
                    currentPage={currentPage}
                    customStyles={customStyles}
                    selectableRowsComponent="input"
                    selectAllRows={isAllSelected}
                    clearSelectedRows={toggleSelect}
                    selectableRows={true}
                    onSelectAllChange={handleMasterCheckboxChange}
                    noDataComponent={<NoResultsTeam/>}
                />
            </div>
            <DeleteUser
                refrence={tableActionRef}
                isOpen={isOpen}
                userData={userData}
                selectedItems={selectedItems}
                onClose={onClose}
                setUserList={setUserList}
                togglePopup={togglePopup}
                setOpen={setOpen}
                setSelectAll={setSelectAll}
                setSelectedItems={setSelectedItems}
                userList= {userList}
                setUserData= {setUserData}
                setToggleSelect={setToggleSelect}
                toggleSelect={toggleSelect}
                setOpenPopupitem={setOpenPopupitem}
            />
        </>
    );
};

export default UsersTable;
