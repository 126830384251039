import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import close from "../../images/close.svg"

function AddCategoryPage(props) {
    const { isOpen, onClose, data, setCategory, setSelectedCategory, isCategoryPage, updateCategoriesData, userId, currentPage, setCurrentPage, fetchData, setOpenNotify} = props;

    const modelRef = useRef(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }

        const checkIfClickedOutside = (e) => {
            if( modelRef.current && !modelRef.current.contains(e.target) ) {
                onClose('cancel')
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    const handleInputChange = (e) => {
        setErrorMessage('');
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addCategory();
        }
    };

    const addCategory = async () => {
        setErrorMessage('');

        var canAddNewCat = true;
        
        if( inputValue.trim() !== '' ) {
            setIsLoading(true);

            if( updateCategoriesData != undefined ) {
                
                const result = await updateCategoriesData( '', inputValue.trim(), 'add');

                if( result != true ) {
                    setErrorMessage(result);
                    setIsLoading(false);
                    canAddNewCat = false;
                }
                
            } else {
                const categoryExists = data.some((existingCategory) =>
                    existingCategory.toLowerCase() === inputValue.toLowerCase()
                );

                if( categoryExists ) {
                    // Display an error message or take appropriate action here
                    setErrorMessage("This category already exists.");
                    setIsLoading(false);
                    canAddNewCat = false;
                }
            }

            if( canAddNewCat ) {
                //make first letter capital
                const new_iv = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                const newCategoryData = {
                    author_id: userId,
                    name: new_iv,
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id
                }

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/categories/add`,
                    newCategoryData,
                )

                if (response.status === 200) {
                    if( updateCategoriesData != undefined ) {
                        if( currentPage != 1 ) {
                            setCurrentPage(1)
                        } else {
                            fetchData()
                        }
                        onClose();
                    } else {
                        if( isCategoryPage !== 'no') {
                            setCurrentPage(1)
                        } else{
                            setCategory((prevValues) => [...prevValues, new_iv]);
                            setSelectedCategory((prevValues) => [...prevValues, new_iv]);
                        }
                        onClose('create');
                    }
                    setOpenNotify(true)
                    setIsLoading(false);
                    setInputValue('');
                    setErrorMessage('');
                }

                if (response.status === 201) {
                    setIsLoading(false);
                    setErrorMessage(response.data.message);
                }

                if (response.status === 500) {
                    setIsLoading(false);
                }

                if (response.status === 400) {
                    setIsLoading(false);
                }

                if(response) {
                    setIsLoading(false);
                }

            }
        } else {
            setErrorMessage("Category name is required!");
            setIsLoading(false);
        }
    };


    return (
        <>
            {isOpen && (
                <div className='popupModal' ref={modelRef}>
                    <div className='popupTop popupTopClose closePlan'>
                        <img src={close} alt="close-icon" onClick={onClose}></img>
                    </div>
                    <h3 className='popupModalTitle'>New Category</h3>
                    <div className="popupFields">
                        {/* <label className="fieldsLabel">Name</label> */}
                        <input id="category_name_inp" ref={inputRef} value={inputValue} onChange={handleInputChange} onKeyPress={(e) => handleKeyPress(e)} type="text" placeholder="Category name" autocomplete="off" />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    <div className="popupModalBtn">
                        <button className="cancel secondary-button button" type="button" onClick={() => { onClose('cancel'); setInputValue(''); setErrorMessage(''); }} >Cancel</button>
                        <button className="savePicture primary-button button" type="button" onClick={addCategory}>
                        {isLoading ?
                          <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        :
                        <>
                            Create
                        </>}
                        </button>
                    </div>

                </div>
            )}
        </>
    )
}

export default AddCategoryPage