import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import close from "../../images/close.svg"

function EditCategoryModel(props) {
    const { isOpen, onClose, categoryId, userId, categoryName, updateCategoriesData, setEditNotify } = props;

    const modelRef = useRef(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(categoryName);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
        }

        const checkIfClickedOutside = (e) => {
            if( modelRef.current && !modelRef.current.contains(e.target) ) {
                onClose('cancel')
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
        
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            editCategory();
        }
    };

    const editCategory = async () => {
        setErrorMessage('');
        
        if( inputValue.trim() !== '' ) {

            setIsLoading(true);

            //make first letter capital
            const new_iv = inputValue.trim().charAt(0).toUpperCase() + inputValue.slice(1);

            const updateStatus = await updateCategoriesData(categoryId, new_iv, 'edit');

            if( updateStatus != true ) {
                setErrorMessage(updateStatus);
            }

            if( updateStatus == true ) {
                
                const newCategoryData = {
                    author_id: userId,
                    name: new_iv,
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id
                }

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/categories/updatecategory/${categoryId}`,
                    newCategoryData,
                );
                if( response.status == 201 ) {
                    setErrorMessage(response.data.message);
                }

                if( response.status == 200 ) {
                    setErrorMessage('');
                    onClose(); 
                    setEditNotify(true);
                }
            }
            setIsLoading(false);
        } else {
            // Display an error message or take appropriate action here
            setErrorMessage("Category name is required!");
            setIsLoading(false);
        }
    };


    return (
        <>
            {isOpen && (
                <div className='popupModal' ref={modelRef} >
                    <div className='popupTopClose'>
                        <img src={close} alt="close-icon" onClick={onClose}></img>
                    </div>
                    <h3 className='popupModalTitle'>Edit Category</h3>
                    <div className="popupFields">
                        <label className="fieldsLabel">Name</label>
                        <input id="category_name_inp" className='inputFields' ref={inputRef} value={inputValue} onChange={handleInputChange} onKeyPress={(e) => handleKeyPress(e)} type="text" placeholder="Category name" autocomplete="off" />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                    </div>
                    <div className="popupModalBtn">
                        <button className="cancel secondary-button button" type="button" onClick={() => { onClose('cancel'); setInputValue(''); setErrorMessage(''); }} >Cancel</button>
                        <button className="savePicture primary-button button" type="button" onClick={editCategory}>
                        {isLoading ?
                            <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                            </div>
                        :
                        <>
                            Update
                        </>}
                        </button>
                    </div>

                </div>
            )}
        </>
    )
}

export default EditCategoryModel