import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";

import close from '../../images/close.svg'
import fileImageIcon from "../../images/upload.svg"
import UploadedPopupMedia from './UploadedPopupMedia';
import '../../App.css'

function UploadMediaPopup(props) {
    const { id } = useParams();
    const { showUploadMediaPopup, setShowUploadMediaPopup, setSelectedProfileImage, selectMediaLoading, uploadMediaPopupFor, uploadMediaPopupTab } = props;
    const fileInputRef = useRef(null);
    const popupRef = useRef();
    const [searchInput, setSearchInput] = useState("");
    const [selectedMeidaType, setSelectedMeidaType] = useState([]);
    const [selectedDateList, setSelectedDateList] = useState([]);
    const [currentTab, setCurrentTab] = useState(uploadMediaPopupTab);
    const [loadingFiles, setloadingFiles] = useState(false);
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [userMedia, setUserMedia] = useState([]);
    const [validateFileImage, setValidateFileImage] = useState(false);
    const [dateList, setDateList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedImageGallery, setSelectedImageGallery] = useState([]);
    const [imageGalleryloadingStates, setimageGalleryLoadingStates] = useState([]);
    const [uploadNewmedia, setUploadNewmedia] = useState(false);
    const [selectedItems, setSelectedItems] = useState([])
    const [errorMessages, setErrorMessages] = useState([])
    const [fileList, setFileList] = useState({
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    });
    const popupTitles = {
        'cover-picture': 'Cover Picture',
        'profile-picture': 'Profile Picture',
        'feature-image': 'Featured Image',
        'docfiles-selection': 'File Library',
        'video-file': 'Video Library',
        'images-file': 'Image Library',
        'editor-image': 'Select Image',
        'editor-video': 'Video Library'
    }
    const fileType = {
        'cover-picture': '^image/',
        'profile-picture': '^image/',
        'editor-image' : '^image/',
        'feature-image': '^image/',
        'images-file': '^image/',
        'video-file': '^video/',
        'editor-video': '^video/',
        'docfiles-selection': 'application/pdf,application/doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint.presentation.macroenabled.12,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroenabled.12'
    }

    const allowFileType = {
        'cover-picture' : 'image/*',
        'profile-picture' : 'image/*',
        'editor-image' : 'image/*',
        'feature-image': 'image/*',
        'images-file': 'image/*',
        'video-file': 'video/*',
        'editor-video': 'video/*',
        'docfiles-selection': '.pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx'
    }

    const popupTitle = popupTitles[uploadMediaPopupFor];

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    const maxSizeInBytes = 128 * 1024 * 1024;

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showUploadMediaPopup && popupRef.current && !popupRef.current.contains(e.target)) {
                setShowUploadMediaPopup(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showUploadMediaPopup]);

    useEffect(() => {
        if (uploadMediaPopupFor === 'video-file') {
            setFileList({
                '^video/': 'Videos',
            });
        } else if (uploadMediaPopupFor === 'docfiles-selection') {
            setFileList({
                'application/pdf': 'PDF',
                'application/doc': 'DOC',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
                'application/vnd.ms-powerpoint': 'PPT',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
                'application/vnd.ms-excel': 'XLS',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            });
        } else {
            setFileList({
                '^image/': 'Images',
            });
        }
    }, [uploadMediaPopupFor]);

    useEffect(() => {
        fetchData(searchInput, selectedMeidaType?.join(','), selectedDateList?.join(','));
    }, [searchInput, selectedMeidaType, selectedDateList]);

    const fetchData = async (search = '', type = '', date = '') => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getMedia/${id}?search=${search}&type=${fileType[uploadMediaPopupFor]}&date=${date}`,
            )
            setUserMedia(response.data.result);
            setDateList(response.data.monthYearList)

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }

    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        await handleMeidaUpload(files);

        setIsImageDragging(false);
    };

    const handleImageGalleryUpload = async (e) => {
        const files = e.target.files;
        await handleMeidaUpload(files);
    }

    const handleMeidaUpload = async (files) => {
        let newErrorMessages = [];
        setErrorMessages([]);
        if (files) {
            let uploadedMediaId = 1;

            for (const file of files) {
                const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));

                if (!isAllowedFileType) {
                    const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                    newErrorMessages.push(errorMessage);
                    continue; // Skip this file
                }

                if (file.size > maxSizeInBytes) {
                    const errorMessage = `File ${file.name} exceeds the maximum size of 128MB.`;
                    newErrorMessages.push(errorMessage);
                    continue; // Skip this file
                }

                const formData = new FormData();
                formData.append('files', file); // Append each file individually

                const uploadMedia = {
                    _id: uploadedMediaId,
                    name: file.name,
                    description: '',
                    alt: '',
                    type: file.type,
                    size: file.size,
                    object_id: "",
                    key: "",
                    url: "",
                    createdAt: "",
                    isLoading: true
                }

                uploadedMediaId = uploadedMediaId + 1;

                setUserMedia((prevUserMedia) => [uploadMedia, ...prevUserMedia]);
                setCurrentTab('media');

                try {
                    const res = await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    );
                    // Update the uploadMedia object with the response data
                    setUserMedia((prevUserMedia) =>
                        prevUserMedia.map((media) =>
                            media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                        )
                    );
                } catch (error) {
                    const errorMessage = `Failed to upload file ${file.name}.`;
                    newErrorMessages.push(errorMessage);
                    console.log(error);
                }
                setErrorMessages(newErrorMessages);
            }
        } else {
            setIsImageDragging(false);
            setloadingFiles(false);
        }
        setErrorMessages(newErrorMessages);
    }


    return (
        <div className="popupContainer uploadMediaPopup">
            <div className='popupOverlay'></div>
            <div className='popupBlock popupBlockPlan' ref={popupRef}>
                <div className='popupBlock-wrapper uploadMediaPopupOverflow'>
                    <div className="popupBlock-inner">
                        <div className="popupTop popupTopClose closePlan">
                            <img src={close} onClick={e => setShowUploadMediaPopup(false)} alt="Icon" />
                        </div>

                        <div className="popupContent">
                            <p className="popupTitle">{popupTitle}</p>
                            <ul className="tabTitle">
                                <li className={`${currentTab === 'upload' ? 'active' : ''}`} onClick={() => setCurrentTab('upload')}>Upload</li>
                                <li className={`${currentTab === 'media' ? 'active' : ''}`} onClick={() => setCurrentTab('media')}>Media Library</li>
                            </ul>
                        </div>
                        {currentTab === 'upload' ? (
                            <div className={`newpageLeftRow textBlock ulpoadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                                <div className={isImageDragging ? `draging fileUploadBox` : `fileUploadBox`} onDragOver={handleFileDragOver} onDragLeave={handleFileDragLeave} onDrop={handleGalleryImageDrop} >
                                    <input type="file" ref={fileInputRef} id="pulication-file" multiple onChange={handleImageGalleryUpload} hidden accept={allowFileType[uploadMediaPopupFor]} />
                                    <div className="uploadFileBlock" onClick={() => document.getElementById("pulication-file").click()} >
                                        <div className="featureImgMiddleContent">
                                            <img className="fileUploadCover_Img" src={fileImageIcon} alt="FeaturedImage" />
                                            <p className="newPageImageUploadText">
                                                <span>Click to upload</span> or drag and drop{" "}
                                                {(uploadMediaPopupFor === 'cover-picture' || uploadMediaPopupFor === 'profile-picture' || uploadMediaPopupFor === 'feature-image' || uploadMediaPopupFor === 'images-file' || uploadMediaPopupFor === 'editor-image') &&
                                                    <>
                                                        <br />
                                                        <b>Images: </b> JPG, PNG
                                                    </>
                                                }
                                                {(uploadMediaPopupFor === 'video-file' || uploadMediaPopupFor === "editor-video") &&
                                                    <>
                                                        <br />
                                                        <b>Videos: </b> MP4, MOV, AVI, WMV, AVCHD, WebM, FLV
                                                    </>
                                                }
                                                {(uploadMediaPopupFor === 'docfiles-selection') &&
                                                    <>
                                                        <br />
                                                        <b>Files: </b> pdf, docx, doc, pptx, ppt, pptm, xls, xlsx, xlsm
                                                    </>
                                                }
                                                <br />
                                                (max. 128MB)
                                            </p>
                                            {errorMessages && errorMessages.length > 0 && (
                                                <div className="errorMessages">
                                                    {errorMessages.map((errorMessage, index) => (
                                                        <p key={index} className="error-message">{errorMessage}</p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <UploadedPopupMedia
                                dateList={dateList}
                                setUserMedia={setUserMedia}
                                fetchData={fetchData}
                                userMedia={userMedia}
                                selectedImageGallery={selectedImageGallery}
                                loadingFiles={loadingFiles}
                                imageGalleryloadingStates={imageGalleryloadingStates}
                                setSelectedImageGallery={setSelectedImageGallery}
                                setUploadNewmedia={setUploadNewmedia}
                                uploadNewmedia={uploadNewmedia}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                isLoading={isLoading}
                                setShowUploadMediaPopup={setShowUploadMediaPopup}
                                setSelectedProfileImage={setSelectedProfileImage}
                                selectMediaLoading={selectMediaLoading}
                                uploadMediaPopupFor={uploadMediaPopupFor}
                                setSearchInput={setSearchInput}
                                searchInput={searchInput}
                                selectedMeidaType={selectedMeidaType}
                                setSelectedMeidaType={setSelectedMeidaType}
                                selectedDateList={selectedDateList}
                                setSelectedDateList={setSelectedDateList}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadMediaPopup;