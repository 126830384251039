import React, { useEffect } from 'react'
import close from '../../images/close.svg'
export const UpgradePopUp = (props) => {

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if ( props?.setShowUpgradeBtn && props?.refrence.current && !props?.refrence?.current?.contains(e.target)
          ) {
            props?.setShowUpgradeBtn(false)
          }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [props?.upgradePlan]);

    return (
        <>
            <div className="popupModalOverlay"></div>
            <div className='popupModal upgradepopup' ref={props.refrence}>
                <div className="popupTop popupTopClose closePlan">
                    <img src={close} onClick={() => {props.setShowUpgradeBtn(false)}} alt="Icon" />
                </div>
                <h3 className='popupModalTitle'>{props?.name === "media" ? "Media Upload Fail" : "Limit of data"}</h3>
                <div className="popupModalContent">

                    <p>{props?.name === "media" ? "This file type is not supported under the Free plan. Please upgrade to access more features." : "You have reached the limit of data for this plan. Please upgrade to access more features."}</p>
                </div>
                <div className="popupModalBtn">
                    <button className="savePicture primary-button button" type="button" onClick={() => { props.setShowUpgradeBtn(false) }}>
                        OK
                    </button>
                </div>
            </div>
        </>
    )
}
