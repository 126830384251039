import '../../App.css';
import noResults from '../../images/noResults.svg';
import { Link } from 'react-router-dom';

function NoResults(props) {
    const buttonTo = props.type === 'categories' ? '#' : `/dashboard/${props?.type}`;
    const clickEventHandler = props?.onclickhandle || null;

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">Your {props?.name} is empty</p>
                <p className='noResDesc'>You haven’t added any media yet. Start uploading images, videos, and files to enrich your content and bring your ideas to life.</p>
                <button className='noResButton button'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5.2V10.8M5.2 8H10.8M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <Link className='editTitle noResult-button' to={buttonTo} onClick={(e) => {
                            if(props.name === 'Media Library') {
                                e.preventDefault();
                            }
                            if(props.mediaPopup) {
                                document.getElementById("pulication-file").click();
                            }
                            if(clickEventHandler) clickEventHandler();
                        }}>
                        Upload media
                    </Link>
                </button>
            </div>
        </div>
    );
}

export default NoResults;
