import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';

import { useParams } from "react-router-dom";
import more from '../../images/more.svg'
import Delete from "../../images/delete.svg";
import iconDropDown from "../../images/icondropdown.svg";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import search from "../../images/search.svg";
import plus from '../../images/plus.svg'
import penIcon from "../../images/pen-img.svg";

import Pagination from "../../components/Pagination";
import NoResultsPages from "./NoResultPages";
import DeletePageModal from "./DeletePageModel";
import Notification from "../../components/Notification";
import { DataTableList } from "../../components/DataTable/DataTableList";

const Pages = (props) => {
    const { id } = useParams();
    const actionRef = useRef();
    const pageStatusRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Pages");
    props.setHideUserInfo(false);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userStatusSelect, setPageStatusSelect] = useState(false);
    const [selectedPageStatus, setSelectedPageStatus] = useState([]);

    const homagepage_id = btoa(`homepage-${id}`);
    const contactpage_id = btoa(`contactpage-${id}`)
    const [originalData, setOriginalData] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)

    const statusList = ['Published', 'Draft', 'Deleted'];
    const excludedItemIds = [homagepage_id, contactpage_id];
    const [readFields, setReadFields] = useState(true);
    const [openNotify, setOpenNotify] = useState(false);
    const [multiDeteleNotify, setMultiDeteleNotify] = useState(false);
    const [selectedPageLenght, setSelectedPageLenght] = useState(0)

    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    
    const [columnWidths, setColumnWidths] = useState([]);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isOpenPopupIndex, setIsOpenIndexPopup] = useState(false);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pageStatusRef.current && !pageStatusRef.current.contains(event.target)) {
                setPageStatusSelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem(null);
                setOpen(false);
            }
            if (actionRef.current && !actionRef.current.contains(event.target)) {
                setOpenPopupitem(null);
            }
        };
        
        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        
        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        const status = selectedPageStatus.length > 0 ? selectedPageStatus.join(',') : '';
        setIsLoading(false)
        try {
            setIsLoading(true)
            setIsLoadingData(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/pageslist/${id}?page=${currentPage}&search=${search}&status=${status}`,
            )

            const result = response?.data?.result;
            if(result?.length <= 0){
                setIsLoadingData(false)
            }
            setOriginalData(result);
            setTotalPage(response?.data?.totalPages || 1)
            //setAllFiltered(response?.data?.result);
            setSelectedPageLenght(0)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPageStatus]);
    
    useEffect(() => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setDebounceTimeout(
            setTimeout(() => {
                fetchData();
            }, 1000) 
        );

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [id, currentPage, searchInput, selectedPageStatus]);

    useEffect(() => {
        var result = [];
        if (originalData !== undefined) {
            originalData.forEach(element => {
                if (result.indexOf(element.status) === -1) {
                    result.push(element.status);
                }
            });
        } else {
            setPageStatusSelect(false);
            setOriginalData(originalData);
        }
    }, [originalData])

    const togglePopup = (index) => {
        if (index === openPopupitem) {
            setOpenPopupitem(null);
            setIsOpenIndexPopup(false)
        } else {
            setOpenPopupitem(index);
            setIsOpenIndexPopup(true)
            setOpen(false);
        }
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedRows.length != 0) {
            setOpen(true);
        }
    };

    const onClose = (e) => {
        setOpen(false);
    };

    const calculateColumnWidths = async () => {
        return new Promise((resolve) => {
            const tempWidths = originalData?.reduce((acc, row) => {
                Object.keys(row).forEach((key) => {
                    const dataValue = row[key].toString();
                    const title = row[key];

                    // Compare title length with data content length and take the max

                    let maxContentLength = 0;

                    if (key === 'status') {
                        if(row?.status === 'Published'){
                            maxContentLength = Math.max(dataValue.length + 2.5);
                        } else{
                            maxContentLength = Math.max(dataValue.length + 3);
                        }
                    } else {
                        maxContentLength = Math.max(dataValue.length, title.length);
                    }

                    if (!acc[key] || maxContentLength > acc[key]) {
                        acc[key] = maxContentLength;
                    }
                });
                return acc;
            }, {});
            if(tempWidths !== undefined){
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    if (key === 'createdAt' || key === 'updatedAt') {
                    } else if (key === 'categories') {
                        originalData.forEach((row) => {
                            const categoryWidth = tempWidths[key] * 10 + 50;
                            acc[key] = `${categoryWidth}px`;
                        });
                    } else {
                        acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    }
                    return acc;
                }, {});

                setColumnWidths(widthMap); // Set the calculated column widths
            }
            resolve(); // Resolve the promise once widths are set
        });
    };

    useEffect(() => {
        if (originalData?.length > 0) {
            calculateColumnWidths();
        } 
    }, [originalData]);
    
    useEffect(() => {
        if (Object.keys(columnWidths).length > 0) {
            setTimeout(() => {
                setIsLoadingData(false);
            }, 1000);  
        }
    }, [columnWidths]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpenPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.table-data-dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenPopupIndex,togglePopup]);

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.title.toString().toLowerCase();
        const titleB = rowB?.title.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            const excludedItemIds = [ homagepage_id, contactpage_id];
            const allItemIds = originalData.map((row) => row._id).filter((itemId) => !excludedItemIds.includes(itemId));
            setSelectedRows(allItemIds); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };
    const handleRestore = async (index) => {
    
        let updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepage/${index?._id}`;
        
        await axios.post(
            updateAPI,
            {
                status: index?.previous_status,
                previous_status: index?.previous_status
            }
        );
        fetchData()
    }
    
    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            cell: (row) => {
                return (
                    <>
                    {
                        row?.status === "Deleted" ? 
                            <Link className='editTitle'>{row?.title}</Link> :
                            <Link className='editTitle' to={`/users/${id}/pages/page/${row._id}`}>{row?.title}</Link>
                    }
                    </>
                )
            },
            sortable: true,
            minWidth: columnWidths['title'] && (parseInt(columnWidths['title']) + 50 > 300) ? '300px' : `${parseInt(columnWidths['title']) + 50}px`,
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => row?.status, // Ensure this returns the status value
            cell: (row) => {
                return (
                    <div className='statusTab'><span className={(row?.status === 'Published') ? 'greenStatus' : (row?.status === 'Draft') ? 'draftStatus' : (row?.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {row?.status}</span></div>
                )
            },
            width: columnWidths['status']|| '150px',
            style: { width: columnWidths['status'] || '150px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Last Updated',
            selector: row => formateDate(row?.updatedAt),
            sortable: true,
            paddingLeft: 0,
            width: '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '150px', fontSize: "14px" },
        },
        {
            name: 'Link',
            cell: (row) => {
                let pageUrl;
                let domainUrl = 'https://jourmal-next-front.vercel.app';

                if (props?.userData?.customDomain?.verified && props?.userData?.customDomain?.domain) {
                    domainUrl = props?.userData?.customDomain?.domain;
                }

                if (row?.status === 'Published') {
                    pageUrl = `${domainUrl}/${props?.userData?.profileSlug}/${row.url_address}`;
                } else if (row.status === 'Draft' && row?.publication_type === 'Regular') {
                    pageUrl = `https://reactadminportal.codeptsolutions.com/users/${id}/pages/previewpage/${row?._id}`;
                }

                return (
                    <div className='pagesTableLink'>
                        <Link target={'_blank'} to={pageUrl}>
                            <button className='pagesLinkButton'>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.88252 9.44272L7.11748 10.2078C6.06116 11.2641 4.34855 11.2641 3.29223 10.2078C2.23592 9.15146 2.23592 7.43884 3.29223 6.38252L4.05728 5.61748M10.9427 6.38252L11.7078 5.61748C12.7641 4.56116 12.7641 2.84855 11.7078 1.79223C10.6515 0.735922 8.93884 0.735922 7.88252 1.79223L7.11748 2.55728M5.6066 7.89339L9.3934 4.10659" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {row?.status === 'Published' ? 'Link' : 'Preview'}
                            </button>
                        </Link>
                    </div>
                );
            },
            width: 
            (((selectedPageStatus.includes('Published') && selectedPageStatus.includes('Draft')) || (selectedPageStatus.includes('Published') && selectedPageStatus.includes('Deleted')))) ? `${parseInt(columnWidths['status']) - 49}px` : 
            (selectedPageStatus.includes('Published')) ? `${parseInt(columnWidths['status']) - 70}px` : 
            ((selectedPageStatus.includes('Draft')) && (((!selectedPageStatus.includes('Published')) && (!selectedPageStatus.includes('Deleted'))))) ? `${parseInt(columnWidths['status']) - 14}px` :
            ((selectedPageStatus.includes('Deleted')) && (((!selectedPageStatus.includes('Published')) && (!selectedPageStatus.includes('Draft')))) || ((selectedPageStatus.includes('Deleted') && selectedPageStatus.includes('Draft')))) ? `${parseInt(columnWidths['status']) - 34}px` :
            `${parseInt(columnWidths['status']) - 47}px`,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)}>
                        <img src={more} alt="More" />
                    </div>
    
                    {(isOpenPopupIndex && (openPopupitem && openPopupitem._id == row._id)) &&
                        <div className="table-data-dropdown">
                            <ul>
                                {
                                    row?.status === "Deleted" ? 
                                    <li onClick={() =>{handleRestore(row)}}>
                                        <span>
                                            Restore
                                        </span>
                                    </li> : 
                                    <li>
                                        <span>
                                            <Link className="table-edit-action" to={`/users/${id}/pages/page/${row._id}`} onClick={handleActionEvent} >
                                                <img src={penIcon} alt="pen-icon" />
                                                Edit
                                            </Link>
                                        </span>
                                    </li>
                                }
                                {!excludedItemIds.includes(row._id) &&
                                    <li>
                                        <span onClick={() => handleDeleteActionEvent(row)} >
                                            {row?.status !== "Deleted" && <img src={Delete} alt="pen-icon" />}
                                            Delete
                                        </span>
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            // minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px' , paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        }
    ];
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }
    const selectableRowDisabled = (row) => {
        if( row.type === 'home' || row.type === 'contact' ) {
            return true;
        }

        return false;
    }


    return (
        <>
            <section className="dashboard page-list">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pagesNew">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search pages..."}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields}
                                    onFocus={() => setReadFields(false)}
                                    onBlur={() => setReadFields(true)}
                                />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown" ref={pageStatusRef}>
                                <button className="selectFields" onClick={(e) => setPageStatusSelect(!userStatusSelect)}>
                                    All Pages{" "} {selectedPageStatus.length > 0 ? `(${selectedPageStatus.length})` : ""} <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userStatusSelect === true) ? <CheckboxActionDropdown options={statusList} selectedOptions={selectedPageStatus} changeOption={setSelectedPageStatus} /> : ''
                                }
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>
                            <div className="add-new-member">
                                <Link to={`/users/${id}/pages/page/`}>
                                    <button className="form-btn primary-button button">
                                        <img src={plus} alt="plus" />
                                        New Page
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`pagesTable dataTable-list ${(isLoadingData) ? 'loading-list' : ''}`}>
                        <DataTableList
                            columns={columns}
                            data={originalData}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows?.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds?.length === originalData?.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowDisabled= {selectableRowDisabled}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            clearSelectedRows={toggleSelect}
                            type="page" 
                            name="pages"
                            selectableRows={true}
                            noDataComponent={<NoResultsPages />}
                        />
                    </div>
                    
                </div>
            </section>

            <DeletePageModal isOpen={isOpen} item={openPopupitem} selectedItems={selectedRows} onClose={onClose} setOpen={setOpen} originalData={originalData} fetchData={fetchData} setOpenNotify={setOpenNotify} setMultiDeteleNotify={setMultiDeteleNotify} setSelectedPageLenght={setSelectedPageLenght} setSelectedItems={setSelectedRows}  setToggleSelect={setToggleSelect} toggleSelect={toggleSelect} refrence={popupRef}/>
            { openNotify && 
                <Notification title={`The page was successfully deleted!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { multiDeteleNotify && 
                <Notification title={`${selectedPageLenght} pages were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeteleNotify} />
            }
        </>
    );
}
export default Pages;