import React, { useState, useRef, useEffect } from "react";
import { Helmet } from 'react-helmet';

import {formattedDate, isUserAdmin} from "../../utils/functions";

import DeleteTeamMember from "./DeleteTeamMember";

import dots from "../../images/dots-icon.svg"
import avatar from "../../images/profile-user.svg"
import Delete from "../../images/delete.svg";


const ProfileInfo = ({userData}) => {

    const firstName = userData?.firstName;
    const lastName = userData?.lastName;
    const email = userData.email;
    const role = userData.role;
    const addedDate = userData.createdAt;

    const [isOpen, setOpen] = useState(false);
    const [isOpenPopup, setOpenPopup] = useState(false);

    const ref = useRef();
    const tableActionRef = useRef();
      

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }

            if (tableActionRef.current && !tableActionRef.current.contains(event.target)) {
                setOpenPopup(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    
    const togglePopup = () => {
        setOpen(!isOpen);
    };

    const onClosePopup = () => {
        setOpenPopup(false)
    }

    const capitalize = (str) => {
        if( str ) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
         return str;
    };

    return (
        <>
            <Helmet>
                <title>{`${capitalize(firstName)} ${capitalize(lastName)} | Persone`}</title>
            </Helmet>
            <div className="profile-section">
                <div className="profile-card">
                    <div className="profile-item">
                        <div className="profile-item-card">
                            <div className="profile-image">
                                <img src={(userData?.profileMediaUrl !=="" && userData?.profileMediaUrl !== undefined )? userData?.profileMediaUrl : avatar} alt="profile-img" />
                            </div>
                            <div className="profile-content">
                                <h2 className="profile-title">{firstName} {lastName}</h2>
                                <p className="profile-text">{email}</p>
                                <span className={`profile-btn ${role}`}>{role}</span>
                            </div>
                        </div>
                    </div>
                    <div className="profile-item">
                        <div className="profile-date-content">
                            <p className="profile-date">
                                Added:
                                <span className="profile-sub-text">
                                {formattedDate(addedDate)}
                                </span>
                            </p>

                            {isUserAdmin() && (<div className="action-wrapp" ref={ref}>
                                <div className="icon-dots secondary-button button" onClick={togglePopup} >
                                    <img src={dots} alt="More" />
                                </div>
                                {isOpen && (
                                    <div className="popup-container">
                                        <div className="popup-delete" onClick={() => {setOpenPopup(true); setOpen(false);}} >
                                            <span>
                                                <img src={Delete} alt="Delete-icon" />
                                                Delete
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <DeleteTeamMember
                refrence={tableActionRef}
                isOpen={isOpenPopup}
                item={userData}
                setOpen={setOpenPopup}
                onClose={onClosePopup}
                navigate= "team"
            />
        </>

    );
};

export default ProfileInfo;
