import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, Link } from "react-router-dom";
import axios from "axios";

import ProfileInfo from "./ProfileInfo";
import General from "./General";
import Password from "./Password";
import AssistantHistory from "./AssistantHistory";
import SupportHistory from "./SupportHistory";
import { PageTabs, PageNavigation } from './PageTabs';

import Home from "../../images/home.png";
import iconDivider from "../../images/chevron-right-light.svg";

import { isUserAdmin } from "../../utils/functions";

const TeamDetail = () => {
    const { id } = useParams();

    const [activePage, setActivePage] = useState("General");
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isUserUpdate,setUserUpdate] = useState(false)

    const [ProfileTabs, setProfileTabs] = useState([
        { name: 'General', href: `/team/${id}/general` },
        { name: 'Password', href: `/team/${id}/password` }
    ]);

    const assistantHistoryFor = [ 'admin', 'assistant' ];
    const supportHistoryFor = [ 'support' ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getmember/${id}/`);
                setUserData(response.data.result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // Call the async function immediately
    }, [id, isUserUpdate]);

    useEffect(() => {
        if (userData.role && assistantHistoryFor.includes(userData.role)) {
            // Check if "Assistant History" tab already exists
            const isAssistantHistoryTabExist = ProfileTabs.some(tab => tab.name === 'Assistant History');
            if (!isAssistantHistoryTabExist) {
                setProfileTabs(prevTabs => [
                    ...prevTabs,
                    { name: 'Assistant History', href: `/team/${id}/assistant-history` }
                ]);
            }
        }

        if (userData.role && supportHistoryFor.includes(userData.role)) {
            // Check if "Support History" tab already exists
            const isSupportHistoryTabExist = ProfileTabs.some(tab => tab.name === 'Support History');
            if (!isSupportHistoryTabExist) {
                setProfileTabs(prevTabs => [
                    ...prevTabs,
                    { name: 'Support History', href: `/team/${id}/support-history` }
                ]);
            }
        }
    }, [userData]);


    const handleFormSubmit = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    };
    return (
        <>
            <div className="breadcrumbs">
                <Link to={'/'} ><img src={Home} className="home-icon" alt="home-icon" /></Link>
                <img className="divider" src={iconDivider} alt="dropdown" />
                <Link to={`/team`} ><span className="">Team</span></Link>
            </div>
            <ProfileInfo userData={userData} />

            <PageTabs tabs={ProfileTabs} activePage={activePage} setActivePage={setActivePage} isUserAdmin={isUserAdmin} />
            <PageNavigation tabs={ProfileTabs} activePage={activePage} setActivePage={setActivePage} />

            <div className="TeamDetails">
                <Routes basename="/team">
                    <Route path="/" element={<General setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} setUserData={setUserData} setUserUpdate={setUserUpdate}/>} ></Route>
                    <Route path="/general" element={<General setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} setUserData={setUserData} setUserUpdate={setUserUpdate}/>} ></Route>
                    {isUserAdmin() && <Route path="/password" element={<Password setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} />} ></Route>}
                    { assistantHistoryFor.includes( userData.role ) && <Route path="/assistant-history" element={<AssistantHistory setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} />} ></Route> }
                    { supportHistoryFor.includes( userData.role ) && <Route path="/support-history" element={<SupportHistory setActivePage={setActivePage} handleFormSubmit={handleFormSubmit} isLoading={isLoading} userData={userData} />} ></Route> }
                </Routes>
            </div>

        </>
    )
};

export default TeamDetail;