import React from 'react'
import { Helmet } from 'react-helmet';
import UsersTable from "./UsersTable";

const UsersList = () => {
    return (
        <>
            <Helmet>
                <title>Users | Persone</title>
            </Helmet>
            <div className="dashboard-top-left">
                <h2 className="dashboard-top-text">Users</h2>
                <p className="dashboard-top-subtext">
                    Manage and organize user accounts efficiently, with tools to view, edit, and oversee all users in one place.
                </p>
            </div>
            <UsersTable />
        </>
    )
};

export default UsersList;
