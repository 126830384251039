import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';

import more from "../../images/more.svg";
import Delete from "../../images/delete.svg";
import penIcon from "../../images/pen-img.svg";
import iconDropDown from "../../images/icondropdown.svg";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import search from "../../images/search.svg";
import plus from '../../images/plus.svg'

import NoResultsPublication from "./NoResultPublication";
import DeletePublicationModal from "./DeletePublicationModel";
import Notification from "../../components/Notification";
import { DataTableList } from "../../components/DataTable/DataTableList";

const Publication = (props) => {
    const { id } = useParams();

    const ref = useRef();
    const publicationStatusRef = useRef();
    const publicationCatRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Publications");
    props.setHideUserInfo(false);

    const baseUrl = `https://jourmal-next-front.vercel.app/${props.profileSlug}`;
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userStatusSelect, setPublicationStatusSelect] = useState(false);
    const [userCategorySelect, setPublicationCategorySelect] = useState(false);
    const [selectedPublicationCategory, setSelectedPublicationCategory] = useState([]);
    const [selectedPublicationStatus, setSelectedPublicationStatus] = useState([]);
    const [categoryList, setPublicationCategoryList] = useState([]);
    const [readFields, setReadFields] = useState(true);

    const [originalData, setOriginalData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [openNotify, setOpenNotify] = useState(false);
    const [multiDeteleNotify, setMultiDeteleNotify] = useState(false);
    const [selectedPubLenght, setSelectedPubLenght] = useState(0);
    const [columnWidths, setColumnWidths] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isOpenPopupIndex, setIsOpenIndexPopup] = useState(false);

    const statusList = ['Published', 'Draft', 'Deleted'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (publicationStatusRef.current && !publicationStatusRef.current.contains(event.target)) {
                setPublicationStatusSelect(false);
            }

            if (publicationCatRef.current && !publicationCatRef.current.contains(event.target)) {
                setPublicationCategorySelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const abortControllerRef = useRef(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const fetchData = async () => {
        setIsLoading(false);
        setIsLoadingData(false)
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        const search = searchInput.toLowerCase();
        const status = selectedPublicationStatus.length > 0 ? selectedPublicationStatus.join(',') : '';
        const categories = selectedPublicationCategory.length > 0 ? selectedPublicationCategory.join(',') : '';
        try {
            setIsLoading(true);
            setIsLoadingData(true)
            let response;
            if ((search === "") && (selectedPublicationStatus?.length <= 0 )) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/publicationlist/${id}?page=${currentPage}&search=${search}&status=${status}&categories=${categories}`);
            } else {

                response = await axios.get(`${process.env.REACT_APP_API_URL}/pages/publicationlist/${id}?page=${currentPage}&search=${search}&status=${status}&categories=${categories}`, {
                    signal: abortControllerRef.current.signal
                });
            }
            if(response?.data?.result?.length <= 0){
                setIsLoadingData(false)
            }
            setOriginalData(response?.data?.result || []);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    };
    useEffect(() => {
        
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setDebounceTimeout(
            setTimeout(() => {
                fetchData();
            }, 1000) 
        );

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [searchInput, currentPage, selectedPublicationStatus, selectedPublicationCategory, id]);

    const fetchCatData = async () => {
        try {
            setIsLoadingData(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/getpublicationscats/${id}`,
            )
            if (response.data.result.length <= 0 ) {
                setIsLoadingData(false)
            }
            setPublicationCategoryList(response.data.result || [])
        } catch (error) {
            console.log(error, "error");
        }
    }

    useEffect(() => {
        fetchCatData();
    }, [id, currentPage, searchInput, selectedPublicationStatus, selectedPublicationCategory]);


    useEffect(() => {
        fetchCatData();
    }, [id]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPublicationStatus]);

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
            setIsOpenIndexPopup(false)
        } else {
            setOpen(false);
            setOpenPopupitem(item);
            setIsOpenIndexPopup(true)
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpenPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.table-data-dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenPopupIndex,togglePopup]);

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedRows.length != 0) {
            setOpen(true);
        }
        document.body.classList.add('hidden-message');
    };

    const onClose = (e) => {
        setOpen(false);
        document.body.classList.add('hidden-message');
    };

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const calculateColumnWidths = async () => {
        return new Promise((resolve) => {
            const tempWidths = originalData?.reduce((acc, row) => {
                Object.keys(row).forEach((key) => {
                    const dataValue = row[key].toString();
                    const title = row[key];

                    // Compare title length with data content length and take the max

                    let maxContentLength = 0;

                    if (key === 'categories') {
                        const categories = row[key];

                        if (categories.length > 2) {
                            let tempCatLength = 0;

                            for (let i = 0; i < 2; i++) {
                                tempCatLength = tempCatLength + categories[i].length;
                            }

                            maxContentLength = Math.max(tempCatLength + 4.5, title.length);

                        } else {
                            if (categories.length == 0) {
                                maxContentLength = Math.max(dataValue.length + 5);
                            } else {
                                maxContentLength = Math.max(dataValue.length + 3 , title.length);
                            }
                        }

                    } else if (key === 'status') {
                        if(row?.status === 'Published'){
                            maxContentLength = Math.max(dataValue.length + 2.5);
                        } else{
                            maxContentLength = Math.max(dataValue.length + 3);
                        }
                    } else {
                        maxContentLength = Math.max(dataValue.length, title.length);
                    }

                    if (!acc[key] || maxContentLength > acc[key]) {
                        acc[key] = maxContentLength;
                    }
                });
                return acc;
            }, {});
            
            if (tempWidths !== undefined) {
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    if (key === 'createdAt' || key === 'updatedAt') {
                    } else if (key === 'categories') {
                        originalData.forEach((row) => {
                            const categoryWidth = tempWidths[key] * 10 + 50;
                            acc[key] = `${categoryWidth}px`;
                        });
                    } else {
                        acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    }
                    return acc;
                }, {});

                setColumnWidths(widthMap); // Set the calculated column widths
            }
            resolve(); // Resolve the promise once widths are set
        });
    };
 
    // Trigger column width calculation on page load and whenever original data changes.
    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        } 
    }, [originalData]);
    
    useEffect(() => {
        if (Object.keys(columnWidths).length > 0) {
            setTimeout(() => {
                setIsLoadingData(false);
            }, 1000);  
        }
    }, [columnWidths]);

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.title.toString().toLowerCase();
        const titleB = rowB?.title.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            const allIds = originalData.map((row) => row._id)
            setSelectedRows(allIds); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    const handleRestore = async (index) => {
    
        let updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepublication/${index?._id}`;
        
        await axios.post(
            updateAPI,
            {
                status: index?.previous_status,
                previous_status: index?.previous_status
            }
        );
        fetchData()
    }

    const columns = [
        {
            name: 'Title',
            selector: (row) => row?.title,
            cell: (row) => {
                return (
                    <>
                        {
                            row?.status === "Deleted" ? 
                                <Link className='editTitle'>{row?.title}</Link> :
                                <Link className='editTitle' to={`/users/${id}/publications/publication/${row._id}`}>{row?.title}</Link>
                        }
                    </>
                )
            },
            sortable: true,
            minWidth: columnWidths['title'] && (parseInt(columnWidths['title']) + 50 > 300) ? '300px' : `${parseInt(columnWidths['title']) + 50}px`,        
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },

        },
        {
            name: 'Status',
            selector: (row) => row?.status, // Ensure this returns the status value
            cell: (row) => {
                return (
                    <div className='statusTab'><span className={(row?.status === 'Published') ? 'greenStatus' : (row?.status === 'Draft') ? 'draftStatus' : (row?.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}> {row?.status}</span></div>
                )
            },
            width: columnWidths['status'] || '150px',
            style: { width: columnWidths['status'] || '150px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Categories',
            cell: (row) => {
                var category = row?.categories.slice(0, 2)
                var categoryFull = row?.categories.slice(2)
                return (<div className='publicationsCategory'>
                    <div className={row?.categories?.length > 2 ? 'categories-large' : 'categories-small'}>
                        {category?.map((category, index) => (
                            <span key={index}>{category}</span>
                        ))}

                        {(row.categories.length > 2) &&
                            <div className='categoryList'>
                                <span>+{row.categories.length - 2}</span>
                                <div className="categoryTooltip">
                                    {categoryFull.map((item) => (
                                        <p> {item}</p>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>)
            },
            paddingLeft: 0,
            width: columnWidths['categories'] || '130px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: columnWidths['categories'] || '130px', fontSize: "14px" },
        },
        {
            name: 'Last Updated',
            selector: row => formateDate(row?.updatedAt),
            sortable: true,
            paddingLeft: 0,
            width: '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '150px', fontSize: "14px" },
        },
        {
            name: 'Link',
            cell: (row) => {
                let pageUrl;
                let domainUrl = 'https://jourmal-next-front.vercel.app'
                if(props?.userData?.customDomain?.verified && (props?.userData?.customDomain?.domain !== undefined && props?.userData?.customDomain?.domain !== '')){
                    domainUrl = `${props?.userData?.customDomain?.domain}`
                } else{
                    domainUrl = 'https://jourmal-next-front.vercel.app'
                }
                if (row?.status === 'Published') {
                        if (row?.publication_type === 'Video' || row?.publication_type === 'Images' || row?.publication_type === 'File') {
                            pageUrl = `${domainUrl}/${props?.userData?.profileSlug}/${row?.url_address}`;
                        } else if (row?.publication_type === 'Link') {
                            pageUrl = row.share_url;
                        } else {   
                            pageUrl = `${domainUrl}/${props?.userData?.profileSlug}/${row?.url_address}`;
                        }
                } else {
                    if (row.status === 'Draft' && row?.publication_type === 'Regular') {
                        pageUrl = `https://reactadminportal.codeptsolutions.com/users/${id}/publications/previewpublication/${row?._id}`
                    }
                }
                return (
                    <div className='pagesTableLink'><Link target={'_blank'} to={`${pageUrl}`}><button className='pagesLinkButton'>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.88252 9.44272L7.11748 10.2078C6.06116 11.2641 4.34855 11.2641 3.29223 10.2078C2.23592 9.15146 2.23592 7.43884 3.29223 6.38252L4.05728 5.61748M10.9427 6.38252L11.7078 5.61748C12.7641 4.56116 12.7641 2.84855 11.7078 1.79223C10.6515 0.735922 8.93884 0.735922 7.88252 1.79223L7.11748 2.55728M5.6066 7.89339L9.3934 4.10659" stroke="#344054" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                        </svg>
                        {row?.status === 'Published' ? 'Link' : 'Preview'}
                    </button></Link></div>
                )
            },
            paddingLeft: 0,
            width: (((selectedPublicationStatus.includes('Published') && selectedPublicationStatus.includes('Draft')) || (selectedPublicationStatus.includes('Published') && selectedPublicationStatus.includes('Deleted')))) ? `${parseInt(columnWidths['status']) - 49}px` : 
            (selectedPublicationStatus.includes('Published')) ? `${parseInt(columnWidths['status']) - 60}px` : 
            ((selectedPublicationStatus.includes('Draft')) && (((!selectedPublicationStatus.includes('Published')) && (!selectedPublicationStatus.includes('Deleted'))))) ? `${parseInt(columnWidths['status']) - 14}px` :
            ((selectedPublicationStatus.includes('Deleted')) && (((!selectedPublicationStatus.includes('Published')) && (!selectedPublicationStatus.includes('Draft')))) || ((selectedPublicationStatus.includes('Deleted') && selectedPublicationStatus.includes('Draft')))) ? `${parseInt(columnWidths['status']) - 34}px` :
            `${parseInt(columnWidths['status']) - 47}px`,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)} ref={ref}>
                        <img src={more} alt="More" />
                    </div>

                    {(isOpenPopupIndex && (openPopupitem && openPopupitem._id == row._id)) && (
                        <div className="table-data-dropdown">
                            <ul>
                                {
                                    row?.status === "Deleted" ? 
                                    <li onClick={() =>{handleRestore(row)}}>
                                        <span>
                                            Restore
                                        </span>
                                    </li> : 
                                    <li>
                                        <span>
                                            <Link className="table-edit-action" to={`/users/${id}/publications/publication/${row._id}`} onClick={handleActionEvent} >
                                                <img src={penIcon} alt="pen-icon" />
                                                Edit
                                            </Link>
                                        </span>
                                    </li>
                                }
                                <li>
                                    <span onClick={() => handleDeleteActionEvent(row)} >
                                        {row?.status !== "Deleted" && <img src={Delete} alt="pen-icon" />}
                                        Delete
                                    </span>
                                </li>
                            </ul>
                        </div>
                    )}
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            width: '80px',
            paddingRight: '16px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            headerStyle: { textAlign: 'right' },
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <>
            <section className="dashboard publication-list">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageTeam">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search publications..."}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields} 
                                    onFocus={ () => setReadFields(false) } 
                                    onBlur={ () => setReadFields(true) }
                                />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown" ref={publicationStatusRef}>
                                <button className="selectFields" onClick={(e) => setPublicationStatusSelect(!userStatusSelect)}>
                                    All Publications{" "}
                                    {selectedPublicationStatus.length > 0 ? `(${selectedPublicationStatus.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userStatusSelect === true) ? <CheckboxActionDropdown options={statusList} selectedOptions={selectedPublicationStatus} changeOption={setSelectedPublicationStatus} /> : ''
                                }
                            </div>
                            <div className="category-dropdown userrole-dropdown" ref={publicationCatRef}>
                                <button className="selectFields" onClick={(e) => setPublicationCategorySelect(!userCategorySelect)}>
                                    All Categories{" "}
                                    {selectedPublicationCategory.length > 0 ? `(${selectedPublicationCategory.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userCategorySelect === true) ? <CheckboxActionDropdown options={categoryList} selectedOptions={selectedPublicationCategory} changeOption={setSelectedPublicationCategory} /> : ''
                                }
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>
                            <div className="add-new-member">
                                <Link to={`/users/${id}/publications/publication/`}>
                                    <button className="form-btn primary-button button">
                                        <img src={plus} alt="plus" />
                                        New Publication
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`pagesTable dataTable-list ${(isLoadingData) ? 'loading-list' : ''}`} >
                        <DataTableList
                            columns={columns}
                            data={originalData}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === originalData?.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            clearSelectedRows={toggleSelect}
                            selectableRows={true}
                            noDataComponent={<NoResultsPublication />}
                        />
                    </div>
                </div>
            </section>

            <DeletePublicationModal
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedRows}
                onClose={onClose}
                setOpen={setOpen}
                refrence={popupRef}
                fetchData={fetchData}
                originalData={originalData}
                setOpenNotify={setOpenNotify}
                setMultiDeteleNotify={setMultiDeteleNotify}
                setSelectedPubLenght={setSelectedPubLenght}
                setSelectedItems={setSelectedRows}
                setToggleSelect={setToggleSelect}
                toggleSelect={toggleSelect}
            />
            { openNotify && 
                <Notification title={`The publication was successfully deleted!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { multiDeteleNotify && 
                <Notification title={`${selectedPubLenght} publications were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeteleNotify} />
            }
        </>
    );
}
export default Publication;