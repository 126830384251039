import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import close from "../../images/close.svg"

function DeleteNewsletterModal(props) {
    const {item, isOpen, onClose, selectedItems, setOpen, refrence, redirect, fetchData, originalData, setMultiDeteleNotify, setOpenNotify, setSelectedNewsLenght, setSelectedItems, toggleSelect, setToggleSelect } = props;

    const history = useNavigate()

    const [isLoading, setIsLoading] = useState(false);

    const [userPassword, setUserPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [error, setError] = useState(false);
    const [errorMassage, setErrorMassage] = useState('');


    const deleteNewsletter = async (itemIDs) => {
        try {
            setError(false);
            setValidatePassword(false);

            if (userPassword.length > 0) {
                setIsLoading(true);

                const updatePromises = itemIDs?.map(async (itemID) => {
                    const matchedItem = originalData.find(data => data._id === itemID);
                
                    let response;
                    if (matchedItem?.status === "Deleted") {
                        response = await axios.delete(
                            `${process.env.REACT_APP_API_URL}/newsLetter/remove/${itemID}?admin=true&password=${userPassword}&member_id=${JSON.parse(localStorage.getItem("userData"))?._id}`
                        );
                    } else{
                        response =  await axios.post(
                            `${process.env.REACT_APP_API_URL}/newsLetter/update/${itemID}?admin=true`,
                            {
                                status: "Deleted",
                                member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                                password: userPassword 
                            }
                        );
                    }
                    if(response?.status === 200){
                        setSelectedNewsLenght(selectedItems.length);
                        if(selectedItems && selectedItems.length > 1){
                            setMultiDeteleNotify(true);
                        } else {
                            setOpenNotify(true); 
                        }
                        onClose()
                    }
                    setSelectedItems([]);
                    setToggleSelect(!toggleSelect)
                });
                
                await Promise.all(updatePromises);

                if (redirect) {
                    history(`/${redirect}`);
                } else {
                    await fetchData();
                }

                setOpen(false);
                document.body.classList.remove('hidden-message');
                
            } else {
                setValidatePassword(true)
            }

            setIsLoading(false);
            setUserPassword("");
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                setError(true);
                setErrorMassage(error.response?.data?.error);
            }
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            deleteNewsletter( item && item.length != 0 ? [item._id] : selectedItems )
        }
      };

    // Function to get display name from _id
    const getDisplayNameById = (id) => {
        const data = originalData.find(user => user._id === id);
        return data ? `${data.subject}` : "";
    };

    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className='popupModal'  ref={refrence}>
                        <div className='popupTopClose'>
                            <img src={close} alt="close-icon" onClick={onClose}></img>
                        </div>
                        <h3 className='popupModalTitle'>Delete {selectedItems.length === 1 ? 'newsletter' : 'newsletters' }</h3>
                        {selectedItems.length === 1 ? (
                            <p className='popupModalContent'>Are you sure you want to delete this newsletter? This action cannot be undone.</p>
                        ) : (
                            <p className='popupModalContent'>Are you sure you want to delete this newsletters? This action cannot be undone.</p>

                        )}
                        <div className='popupFields password-field'>
                            <label className='fieldsLabel'>Enter Password</label>
                            <input type="password" name='password' placeholder='Enter your password' autoComplete='off' onChange={(e) => setUserPassword(e.target.value)} readOnly={readOnly} onFocus={ () => setReadOnly(false) } onBlur={ () => setReadOnly(true) } onKeyPress={(e) => { handleKeyPress(e) }}/>
                            {validatePassword === true && (
                                <p className="validateError">The field is not filled</p>
                            )}
                            {error === true && (
                                <p className="validateError">{errorMassage}</p>
                            )}
                        </div>
                        <div className="popupModalBtn">
                            <button className="cancel secondary-button button" type="button" onClick={onClose}>Cancel</button>
                            <button className="savePicture primary-button button" type="button" onClick={() => deleteNewsletter( item && item.length != 0 ? [item._id] : selectedItems )}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                : "Delete"}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default DeleteNewsletterModal;