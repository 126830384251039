import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useEffect } from 'react';

import twitterLogo from '../../../../../../images/twitter-logo.png'

const TwitterNodeView = ({ node }) => {
  
  const [errorMessage, setErrorMessage] = useState('');
  const [attachmentRows, setAttachmentRows] = useState([]);

  function parseLikeCount(likeCount) {
    if (!likeCount) return 0;

    const count = likeCount.toLowerCase();
    if (count.includes('k')) {
      return parseFloat(count) * 1000; // Convert '4.5K' to 4500
    }
    if (count.includes('m')) {
      return parseFloat(count) * 1000000; // Convert '2M' to 2000000
    }
    return parseInt(count, 10); // Convert plain numbers like '150'
  }

  const twitterUrl = node?.attrs?.twitterUrl || '';
  const avatarUrl = node?.attrs?.twitterData?.avatarUrl || '';
  const displayName = node?.attrs?.twitterData?.displayName || '';
  const displayTime = node?.attrs?.twitterData?.displayTime || '';
  const likeCount = node?.attrs?.twitterData?.likeCount || ''; 
  const replyCount = node?.attrs?.twitterData?.replyCount || '';
  const retweetCount = node?.attrs?.twitterData?.retweetCount || '';
  const tweetText = node?.attrs?.twitterData?.tweetText?.replace(/\n/g, '<br>') || '';
  const username = node?.attrs?.twitterData?.username || '';
  const attachments = node.attrs?.twitterData?.attachments || [];
  const newAttachmentRows = []; // Create a new array to hold the rows

  for (let i = 0; i < attachments.length; i += 2) {
    if (i + 1 < attachments.length) {
      // Create a row with two images/videos
      newAttachmentRows.push(
        <tr key={i} style={{ marginBottom: '5px' }}>
          <td width="50%" style={{ paddingBottom: '10px', paddingRight: '5px' }}>
            <img
              src={attachments[i]?.type === 'video' ? attachments[i]?.previewUrl : attachments[i]?.url}
              alt="attachment"
              style={{ maxWidth: '100%', height: '200px', objectFit: 'cover', width: "100%" }}
            />
          </td>
          <td width="50%" style={{ paddingBottom: '10px', paddingLeft: '5px' }}>
            <img
              src={attachments[i + 1]?.type === 'video' ? attachments[i + 1]?.previewUrl : attachments[i + 1]?.url}
              alt="attachment"
              style={{ maxWidth: '100%', height: '200px', objectFit: 'cover', width: "100%" }}
            />
          </td>
        </tr>
      );
    } else {
      // Create a row with one image/video (last odd item)
      newAttachmentRows.push(
        <tr key={i}>
          <td colSpan="2">
            <img
              src={attachments[i]?.type === 'video' ? attachments[i]?.previewUrl : attachments[i]?.url}
              alt="attachment"
              style={{ maxWidth: '100%', width: "100%" }}
            />
          </td>
        </tr>
      );
    }
  }

  // Set the new attachment rows to state
  useEffect(() => {
    setAttachmentRows(newAttachmentRows);
  }, [attachments]); // Update when attachments change

  return (
    <NodeViewWrapper>
      <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle data-twitter-url={twitterUrl}>
        {errorMessage ? (
          <p className="error-message">{errorMessage}</p>
        ) :
          <>
            <table className="twitterBlock" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ borderCollapse: "collapse", width: "100%", maxWidth: "520px", margin: "0px auto 20px" }} align="center">
              <tbody>
                <tr style={{ border: "1px solid #8080805c", borderRadius: "6px" }}>
                  <td style={{ padding: "15px" }}>
                    <a href={twitterUrl} target="_blank">
                      <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ borderCollapse: "collapse" }}>
                        <tr>
                          <td width="48">
                            <div className="userPanel">
                              <img style={{ width: "48px", height: "48px", borderRadius: "50%", border: "0", }} src={avatarUrl} alt={username} />
                            </div>
                          </td>
                          <td>
                            <div className="userSubPanel" style={{ marginLeft: "15px" }}  >
                              <p className="userName">{displayName}</p>
                              <p className="userNamewithLink">@{username}</p>
                            </div>
                          </td>
                          <td width="24" align="right" style={{ verticalAlign: "top" }}>
                            <img width="24" height="24" loading="lazy" style={{ border: "0", maxWidth: "24px", marginTop: "5px", }} src={twitterLogo} />
                          </td>
                        </tr> 
                        <tr>
                          <td colspan="3" style={{ paddingTop: "12px" }}>
                            <p className="tweetText" style={{ color: "#344054", fontSize: "16px", lineHeight: "24px", marginBottom: "0", }} dangerouslySetInnerHTML={{ __html: tweetText }}></p>
                          </td>
                        </tr>
                        { attachmentRows &&
                            <tr>
                              <td colspan="3">
                                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{ marginTop: "10px" }}>
                                  {attachmentRows}
                                </table>
                              </td>
                            </tr>
                          }
                        <tr>
                          <td colspan="3" style={{ paddingTop: "10px" }}>
                            <p className="displayTime" style={{ borderBottom: "1px solid #cfd9de", marginBottom: "10px", paddingBottom: "10px", color: "#697882", fontSize: "14px", lineHeight: "17px", fontFamily: "system-ui, Helvetica, Roboto, Calibri, sans-serif", }} >{displayTime}</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <span className="likeCount"><span>{likeCount}</span> {parseLikeCount(likeCount) > 1 ? 'Likes' : 'Like'}</span>
                            <span className="retweetCount"><span>{retweetCount}</span> {Number(retweetCount) > 0 ? 'Retweets' : 'Retweet'}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <button className="replyCount" style={{ cursor: "pointer", textAlign: "center", marginTop: "4px", marginBottom: "4px", padding: "8px", border: "1px solid #ccd6dd", borderRadius: "9999px", color: "#1b95e0", width: "100%", fontWeight: "700", marginTop: "10px", background: "#fff", fontSize: "16px", }}>
                              {Number(replyCount > 0) ? `${replyCount}` : 'Replay'} {Number(replyCount > 1) ? 'Replies' : Number(replyCount == 1) ? 'Replay' : ''}
                            </button>
                          </td>
                        </tr>
                      </table>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        }
      </div>
    </NodeViewWrapper>
  );
};

export const TwitterBlock = Node.create({
  name: 'twitterBlock',

  isolating: true,
  defining: true,
  group: 'block',
  draggable: true,

  addAttributes() {
    return {
      twitterUrl: { default: '' },
      twitterData: { default: {} },
      avatarUrl: { default: '' },
      username: { default: '' },
      displayName: { default: '' },
      formattedTweetText: { default: '' },
      attachmentRows: { default: [] },
      likeCount: { default: '' },
      retweetCount: { default: '' },
      replyCount: { default: '' },
      displayTime: { default: '' },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
        getAttrs: (element) => {  
          const twitterUrl = element.getAttribute('data-twitter-url') || '';
          const twitterData = JSON.parse(element.getAttribute('data-twitter-data') || '{}'); // Parse twitterData
          const avatarUrl = element.getAttribute('data-avatarUrl') || '';
          const username = element.getAttribute('data-username') || '';
          const displayName = element.getAttribute('data-displayName') || '';
          const formattedTweetText = element.getAttribute('data-formattedTweetText') || '';
          const attachmentRows = JSON.parse(element.getAttribute('data-attachmentRows') || '[]'); // Parse attachmentRows
          const likeCount = element.getAttribute('data-likeCount') || '';
          const retweetCount = element.getAttribute('data-retweetCount') || '';
          const replyCount = element.getAttribute('data-replyCount') || '';
          const displayTime = element.getAttribute('data-displayTime') || '';
  
          return {
            twitterUrl,
            twitterData,
            avatarUrl,
            username,
            displayName,
            formattedTweetText,
            attachmentRows,
            likeCount,
            retweetCount,
            replyCount,
            displayTime,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const {
      twitterUrl,
      twitterData,
      avatarUrl,
      username,
      displayName,
      formattedTweetText,
      attachmentRows,
      likeCount,
      retweetCount,
      replyCount,
      displayTime,
    } = HTMLAttributes;
  
    return [
      'div',
      {
        'data-type': this.name,
        'data-twitter-url': twitterUrl || '',
        'data-twitter-data': JSON.stringify(twitterData) || '{}',  // Serialize twitterData to JSON string
        'data-avatarUrl': avatarUrl || '',
        'data-username': username || '',
        'data-displayName': displayName || '',
        'data-formattedTweetText': formattedTweetText || '',
        'data-attachmentRows': JSON.stringify(attachmentRows) || '[]', // Serialize attachmentRows to JSON string
        'data-likeCount': likeCount || '',
        'data-retweetCount': retweetCount || '',
        'data-replyCount': replyCount || '',
        'data-displayTime': displayTime || '',
        class: 'twitter-node-wrapper twitter-block',
      },
    ];

  },


  addCommands() {
    return {
      setTwitterBlock:
        (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                twitterUrl: options?.twitterUrl || '',
                twitterData: options?.twitterData || {},
                avatarUrl: options?.avatarUrl || '',
                username: options?.username || '',
                displayName: options?.displayName || '',
                formattedTweetText: options?.formattedTweetText || '',
                attachmentRows: options?.attachmentRows || '',
                likeCount: options?.likeCount || '',
                retweetCount: options?.retweetCount || '',
                replyCount: options?.replyCount || '',
                displayTime: options?.displayTime || '',
              },
            });
          },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TwitterNodeView); // Attach TwitterNodeView to render embedded tweet
  },
});
