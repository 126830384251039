import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Nestable from 'react-nestable';

import SocialItemList from "./SocialItemList"

import 'react-nestable/dist/styles/index.css';

import plusIcon from '../../images/plus-white.svg';
import Notification from '../../components/Notification';

const SocialNetwork = ({setActivePage, socialNetwork, userData}) => {
    setActivePage("Social networks");
    const { id } = useParams();

    // State declarations
    const [socialArray, setSocialArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [openNotify, setOpenNotify] = useState(false)

    useEffect(() => {
        setSocialArray(socialNetwork);
    }, [socialNetwork]);

    // Function to update the socialArray state
    const updateSocialArray = (itemId, newData) => {
        const updatedArray = socialArray.map(item => {
            if (item.id === itemId) {
                return {
                    ...item,
                    ...newData
                };
            }
            return item;
        });
        setSocialArray(updatedArray);
    };

    // Function to delete an item from socialArray
    function deleteItem(id) {
        const updatedData = socialArray.filter(item => item.id !== id);
        updatedData.forEach((item, index) => {
            item.id = index + 1;
        });
        setSocialArray(updatedData);
    }


    // Function to clear all data
    function clearAll() {
        setCancelLoading(true);
    }

    // Function to add social data
    const addSocialData = async () => {
        try {
            setIsLoading(true);
            const socialNetworkData = {
                social_nework: socialArray.filter(item => item.url !== null && item.url !== ''),
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
            };
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/socialnework/${id}/`,
                socialNetworkData
            );
            setIsLoading(false);
            setOpenNotify(true);
        } catch (error) {
            // setIsLoading(false);
            console.log(error, "Data not added");
        }
    };

    return(
        <div className="socialContent">
            <div className="socialItem">
                <div className='socialBlock'>
                    <div className="socialLeft">
                        <p className='socialMenuBlockTitle'>My social networks</p>
                        <p className='socialMenuBlockSubtitle'> Select your preferred social networks from the list and enter their URLs to link them to your profile.</p>
                    </div>
                    <div className='socialRight'>
                        <Nestable items={socialArray}
                            renderItem={({ item }) => <SocialItemList socialArray={socialArray} updateSocialArray={updateSocialArray} deleteItem={deleteItem} data={item} name={item.title} link={item.url} />}
                            maxDepth={0}
                            onChange={(newData) => {
                                newData.items.map((item, index) => (item.id = index + 1));
                                setSocialArray(newData.items);
                            }}
                            />

                        <button onClick={e => {
                            var data = socialArray;
                            
                            setSocialArray([...socialArray, {
                                id: data.length + 1,
                                title: null,
                                url: null,
                                children: []
                            }])
                        }} className='addToMenu secondary-button button'><img src={plusIcon} alt="Add new social network" /><p>Add a new social network</p></button>
                    </div>
                </div>
            </div>

            <div className="saveButtons">
                <button className={`form-btn save-change navigationBtn primary-button button ${isLoading ? 'btn_loading' : ''}`} onClick={addSocialData}>
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                    : "Save Changes"}
                </button>
            </div>
            {openNotify && 
                <Notification openNotify={openNotify} setOpenNotify={setOpenNotify} type={'success'} title={`Your social networks has been successfully updated!`}/>
            }
        </div>
    )
};
export default SocialNetwork;