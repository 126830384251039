import { useState, useRef, useEffect } from "react";

import NoResultsMedia from "./NoResultsMedia";
import EditMediaPopup from "./EditMediaPopup";
import { formatFileSize, formattedDate } from "../../utils/functions"
import more from "../../images/more-1.svg"


import filePdfIcon from "../../images/file-pdf-icon.svg"
import fileDocIcon from "../../images/file-doc-icon.svg"
import fileDocxIcon from "../../images/file-docx-icon.svg"
import filePptIcon from "../../images/file-ppt-icon.svg"
import filePptxIcon from "../../images/file-pptx-icon.svg"
import filePptmIcon from "../../images/file-pptm-icon.svg"
import fileXlsIcon from "../../images/file-xls-icon.svg"
import fileXlsxIcon from "../../images/file-xlsx-icon.svg"
import fileXlsmIcon from "../../images/file-xlsm-icon.svg"
import fileVideoIcon from "../../images/file-video-icon.svg"
import fileAudioIcon from "../../images/file-audio-icon.svg"
import { useParams } from "react-router";
import axios from "axios";
import { UpgradePopUp } from "../../components/modal/UpgradePopUp";
import { DataTableList } from "../../components/DataTable/DataTableList";


const ListLayout = ({ selectedItems, setSelectedItems, userMedia, setMediaItem, uploadNewmedia, setUploadNewmedia, isLoading, paymentMethods, setSaveNotify, isLoadingData, setIsLoadingData, toggleCleared, setDeleteConfirmation }) => {
    const ref = useRef();
    const [openPopupIndex, setOpenPopupIndex] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [editedItemId, setEditedItemId] = useState(null);
    const [fileList, setFileList] = useState({ '^image/': 'Images' });
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessages, setErrorMessages] = useState([])
    const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
    const [columnWidths, setColumnWidths] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const { id } = useParams()
    const popupRef = useRef();
    const modelRef = useRef();
    const mediaIconSet = {
        'application/pdf': filePdfIcon,
        'application/doc': fileDocIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileDocxIcon,
        'application/vnd.ms-powerpoint': filePptIcon,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': filePptxIcon,
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': filePptmIcon,
        'application/vnd.ms-excel': fileXlsIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsxIcon,
        'application/vnd.ms-excel.sheet.macroenabled.12': fileXlsmIcon,
        'video/mp4': fileVideoIcon,
        'video/mpeg': fileVideoIcon,
        'video/ogg': fileVideoIcon,
        'video/webm': fileVideoIcon,
        'video/3gpp': fileVideoIcon,
        'video/3gpp2': fileVideoIcon,
        'video/x-msvideo': fileVideoIcon,
        'video/quicktime': fileVideoIcon,
        'audio/aac': fileAudioIcon,
        'audio/midi': fileAudioIcon,
        'audio/mpeg': fileAudioIcon,
        'audio/ogg': fileAudioIcon,
        'audio/wav': fileAudioIcon,
        'audio/webm': fileAudioIcon,
        'audio/3gpp': fileAudioIcon,
    }

    useEffect(() => {

        if (paymentMethods?.plan !== 'free_plan') {
            setFileList({
                '^image/': 'Images',
                '^video/': 'Videos',
                'application/pdf': 'PDF',
                'application/doc': 'DOC',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
                'application/vnd.ms-powerpoint': 'PPT',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
                'application/vnd.ms-excel': 'XLS',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            })
        } else {
            setFileList({
                '^image/': 'Images',
            })
        }


    }, [paymentMethods]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
            if (modelRef.current && !modelRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    const handleEditClick = (itemId) => {
        setShowPopup(true)
        setEditedItemId(itemId);
    };
    const maxSizeInBytes = 128 * 1024 * 1024; // 128MB

    const allowFIleType = {
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    }

    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();
        let newErrorMessages = [];
        setErrorMessages([]);
        setShowUpgradeBtn(false)
        setIsImageDragging(false);
        const files = e.dataTransfer.files;

        let uploadedMediaId = 1;

        for (const file of files) {

            const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));


            if (!isAllowedFileType) {
                // console.log(`File type ${file.type} is not allowed.`);
                const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                newErrorMessages.push(errorMessage);

                if (Object.keys(allowFIleType).some((pattern) => new RegExp(pattern).test(file.type))) {
                    setShowUpgradeBtn(true);
                }
                continue; // Skip this file
            }

            if (file.size > maxSizeInBytes) {
                console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                continue; // Skip this file
            }

            setErrorMessages(newErrorMessages)

            const formData = new FormData();
            formData.append('files', file); // Append each file individually

            const uploadMedia = {
                _id: uploadedMediaId,
                name: file.name,
                description: file.name,
                alt: file.name,
                type: file.type,
                size: file.size,
                object_id: "",
                key: "",
                url: "",
                createdAt: "",
                isLoading: true
            }

            uploadedMediaId = uploadedMediaId + 1;

            setMediaItem((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                // Update the uploadMedia object with the response data
                setMediaItem((prevUserMedia) =>
                    prevUserMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                    )
                );
            } catch (error) {
                console.log(error);
            }
        }

        setIsImageDragging(false);
        setErrorMessages(newErrorMessages);
    };

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.name.toString().toLowerCase();
        const titleB = rowB?.name.toString().toLowerCase();

        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const calculateColumnWidths = async () => {
        return new Promise((resolve) => {
            const tempWidths = userMedia?.reduce((acc, row) => {
                // Calculate width for 'Type' column based on (row.object_id).split('.')[1].toUpperCase()
                const typeValue = (row?.object_id).split('.')[1]?.toUpperCase();
                const typeLength = typeValue?.length;
                if (!acc['object_id'] || typeLength > acc['object_id']) {
                    acc['object_id'] = typeLength + 2;
                }
        
                // Calculate width for 'Size' column based on formatFileSize(row.size)
                const sizeValue = formatFileSize(row.size);
                const sizeLength = sizeValue?.length;
                if (!acc['size'] || sizeLength > acc['size']) {
                    acc['size'] = sizeLength - 2;
                }

                const nameValue = row.name;
                const nameLength = nameValue?.length;
                if (!acc['name'] || nameLength > acc['name']) {
                    acc['name'] = nameLength;
                }
                return acc;
            }, {});
        
            if (tempWidths !== undefined) {
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                if(key === 'size'){
                        acc[key] = `${tempWidths[key] * 10 + 70}px`;
                    } else if(key === 'object_id'){
                        acc[key] = `${tempWidths[key] * 10 + 55}px`;
                    } else if(key === 'name'){
                        acc[key] = `${tempWidths[key] * 10 + 60}px`;
                    } else {
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    }
                return acc;
                }, {});
        
                setColumnWidths(widthMap); // Set the calculated column widths
            }
            resolve(); // Resolve the promise once widths are set
        });
    };
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target) && e.target.closest('.dropdown') === null ) {
                setOpenPopupIndex(null); 
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    const columns = [
        {
            name: 'File',
            selector: (row) => row?.name,
            cell: (row) => {
                return (
                    <div className={`picture-main ${row?.isLoading ? 'loading-row' : ''}`}>
                        <div className={`file-picture ${(row?.type).includes('image') ? 'type-image' : 'type-doc'}`}>
                            <img src={mediaIconSet[row?.type] ? mediaIconSet[row?.type] : row?.url} onClick={() => handleEditClick(row?._id)} alt={row?.name} className='item' />
                        </div>
                        <div className='file-data-wrapper' >
                            <div className='file-name' onClick={() => handleEditClick(row?._id)}>
                                {row?.name}
                            </div>
                        </div>
                    </div>
                )
            },
            sortable: true,
            minWidth: columnWidths['name'] && (parseInt(columnWidths['name']) + 50 > 300) ? '300px' : `${parseInt(columnWidths['name']) + 50}px`,  
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },

        },
        {
            name: 'Type',
            selector: (row) => (row?.object_id).split('.')[1]?.toUpperCase(), // Ensure this returns the status value
            width: '110px',
            style: {  paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Size',
            selector: (row) => formatFileSize(row?.size),
            paddingLeft: 0,
            sortable: true,
            width: columnWidths['size'] || '200px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: columnWidths['size'] || '200px', fontSize: "14px" },
        },
        {
            name: 'Date',
            selector: row => formattedDate(row?.createdAt),
            sortable: true,
            paddingLeft: 0,
            width: '110px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='table-action' onClick={() => togglePopup(row)} ref={ref}>
                        <img src={more} alt="More" />
                    </div>
                    {(openPopupIndex?._id === row?._id) &&
                <div className='table-data-dropdown'>
                    <ul>
                        <li>
                            <span onClick={(e) => { e.preventDefault(); handleEditClick(row?._id); setOpenPopupIndex(null); }}>  Edit </span>
                        </li>
                        <li>
                            <span onClick={(e) => { e.preventDefault(); setDeleteConfirmation(true);setSelectedItems([row?._id])}}>  Delete </span>
                        </li>
                    </ul>
                </div>
            }
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            width: '80px',
            paddingRight: '16px',
            style: { textAlign: 'right', width: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];
    useEffect(() => {
        if (userMedia.length > 0) {
            calculateColumnWidths();
        } 
    }, [userMedia]);

    useEffect(() => {
        if (Object.keys(columnWidths).length > 0) {
            setTimeout(() => {
                setIsLoadingData(false);
            }, 1000);  
        }
    }, [columnWidths]);

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedItems([])
        } else {

            const allIds = userMedia.map((row) => row._id);
            setSelectedItems(allIds); // Select all items
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };
    return (
        <>
            <div className={`${isImageDragging ? "draging table-pagination-wrapper pagesTableItem media-list" : "table-pagination-wrapper pagesTableItem media-list"}`} onDragOver={handleFileDragOver}
                onDragLeave={handleFileDragLeave}
                onDrop={handleGalleryImageDrop}>
                <div className={`pagesTable dataTable-list ${(isLoadingData) ? 'loading-list' : ''}`}>
                    <DataTableList
                        columns={columns}
                        data={userMedia}
                        onSelectedRowsChange={({ selectedRows }) => {
                            const selectedIds = selectedRows.map((row) => row._id);
                            setSelectedItems(selectedIds)
                            setIsAllSelected(selectedIds.length === userMedia.length);
                        }}
                        progressPending={isLoading}
                        progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                        customStyles={customStyles}
                        selectableRowsComponent="input"
                        selectAllRows={isAllSelected}
                        onSelectAllChange={handleMasterCheckboxChange}
                        type="media-gallery"
                        name="Media Library"
                        status="all"
                        selectableRows={true}
                        clearSelectedRows={toggleCleared}
                        onclickhandle={() => setUploadNewmedia(!uploadNewmedia)} 
                        noDataComponent={<NoResultsMedia type="media-gallery" name="Media Library" onclickhandle={() => setUploadNewmedia(!uploadNewmedia)} />}
                    />

            </div>
                    {showPopup &&
                        <EditMediaPopup setShowPopup={setShowPopup} item={userMedia.find(item => item._id === editedItemId)} userMedia={userMedia} setUserMedia={setMediaItem} setSaveNotify={setSaveNotify} />
                    }
                    {errorMessages && errorMessages.length > 0 && (
                        <div className="errorMessages">
                            {errorMessages.map((errorMessage, index) => (
                                <>
                                    <p key={index} className="error-message" >{errorMessage}</p>
                                    {/* {showUpgradeBtn && <p className='upgrade-message' >Upgrade Now</p>}  */}
                                </>
                            ))}
                        </div>
                    )}
                    {showUpgradeBtn &&
                        <UpgradePopUp setShowUpgradeBtn={setShowUpgradeBtn} refrence={popupRef} setErrorMessages={setErrorMessages} />
                    }
            </div>
        </>
    )
}

export default ListLayout;