import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import NoResultNewsletter from "./NoResultNewsletter";
import DeleteNewsletterModal from "./DeleteNewsletterModal";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import { DataTableList } from "../../components/DataTable/DataTableList";

import more from "../../images/more.svg";
import Delete from "../../images/delete.svg";
import penIcon from "../../images/pen-img.svg";
import search from '../../images/search.svg'
import plus from '../../images/plus.svg'
import iconDropDown from "../../images/icondropdown.svg";
import Notification from "../../components/Notification";

const Newsletter = (props) => {
    const { id } = useParams();

    const ref = useRef();
    const statusRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Newsletters");
    props.setHideUserInfo(false);

    const [searchInput, setSearchInput] = useState("");
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [selectedNewsletterStatus, setSelectedNewsletterStatus] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const [userFilterSelect, setFilterTypeSelect] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState([])
    const [readFields, setReadFields] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [openNotify, setOpenNotify] = useState(false);
    const [multiDeteleNotify, setMultiDeteleNotify] = useState(false);
    const [selectedNewsLenght, setSelectedNewsLenght] = useState(0);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [columnWidths, setColumnWidths] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isOpenPopupIndex, setIsOpenIndexPopup] = useState(false);
    
    const filterType = ['Send', 'Draft', 'Deleted'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusRef.current && !statusRef.current.contains(event.target)) {
                setFilterTypeSelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        const status = selectedFilterType.length > 0 ? selectedFilterType.join(',') : '';
        setIsLoading(false);
        try {
            setIsLoading(true);
            setIsLoadingData(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/list/${id}?page=${currentPage}&search=${search}&status=${status}`,
            )
            
            if(response?.data?.result?.length <= 0){
                setIsLoadingData(false)
            }
            setOriginalData(response?.data?.result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }
    useEffect(() => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        setDebounceTimeout(
            setTimeout(() => {
                fetchData();
            }, 1000) 
        );

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [id, currentPage, searchInput, selectedFilterType ]);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedFilterType]);

    useEffect(() => {
        var result = [];
        originalData.forEach((element) => {
            var pagestatus = element.status;
            if (selectedNewsletterStatus.includes(pagestatus)) {
                result.push(element);
            }
        });

        if (result.length !== 0) {
            setOriginalData(result);
        } else if (selectedNewsletterStatus.length === 0) {
            setOriginalData(originalData);
        } else {
            setOriginalData([]);
        }
    }, [selectedNewsletterStatus]);

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
            setIsOpenIndexPopup(false)
        } else {
            setOpen(false);
            setOpenPopupitem(item);
            setIsOpenIndexPopup(true)
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpenPopupIndex &&  (event.target.closest('.pagesTableAction') === null && event.target.closest('.table-data-dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenPopupIndex,togglePopup]);

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedRows.length != 0) {
            setOpen(true);
        }
        document.body.classList.add('hidden-message');
    };

    const onClose = (e) => {
        setOpen(false);
        document.body.classList.add('hidden-message');
    };

    const handleRestore = async (index) => {
    
        let updateAPI= `${process.env.REACT_APP_API_URL}/newsLetter/updatestatus/${index?._id}`
        
        await axios.post(
            updateAPI,
            {
                status: index?.previous_status,
                previous_status: index?.previous_status
            }
        );
        fetchData()
    }

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const calculateColumnWidths = async () => {
        return new Promise((resolve) => {
            const tempWidths = originalData?.reduce((acc, row) => {
                Object.keys(row).forEach((key) => {
                    const dataValue = row[key].toString();
                    const title = row[key];
                    
                    let maxContentLength = 0;
                    if (key === 'sendTo' && row[key]?.plan) {
                        const tempdataValue = row[key].plan.toString();
                        maxContentLength = Math.max(13.5, acc['sendTo.plan'] || 0);
                        if(dataValue === "All Subscribers"){
                            acc['sendTo.plan'] = Math.max(13.5, maxContentLength)
                        } else{
                            acc['sendTo.plan'] = Math.max(tempdataValue.length - 2, maxContentLength)
                        }
                    } else if (key === 'status') {
                        if(row?.status === 'Send'){
                            maxContentLength = Math.max(dataValue.length + 3.8);
                        } else{
                            maxContentLength = Math.max(dataValue.length + 3);
                        }
                    } else {
                        maxContentLength = Math.max(dataValue.length, title.length);
                    }

                    if (!acc[key] || maxContentLength > acc[key]) {
                        acc[key] = maxContentLength;
                    }
                });
                return acc;
            }, {});

            if(tempWidths !== undefined){
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    if (key === 'createdAt' || key === 'updatedAt') {
                    } else {
                        acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    }
                    return acc;
                }, {});
                setColumnWidths(widthMap); // Set the calculated column widths
            }
            resolve(); // Resolve the promise once widths are set
        });
    
    };

    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        } 
    }, [originalData]);    
    
    useEffect(() => {
        if (Object.keys(columnWidths).length > 0) {
            setTimeout(() => {
                setIsLoadingData(false);
            }, 1000);  
        }
    }, [columnWidths]);

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.subject.toString().toLowerCase();
        const titleB = rowB?.subject.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(originalData.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    const columns = [
        {
            name: 'Subject',
            selector: (row) => row?.subject,
            cell: (row) => {
                return (
                    <>
                        {
                            row?.status === "Deleted" ? 
                                <Link className='editTitle'>{row?.subject}</Link> :
                                <Link className='editTitle' to={`/users/${id}/newsletters/newsletter/${row._id}`}>{row?.subject}</Link>
                        }
                    </>
                )
            },
            sortable: true,
            minWidth: columnWidths['subject'] && (parseInt(columnWidths['subject']) + 50 > 300) ? '300px' : `${parseInt(columnWidths['subject']) + 50}px`,        
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => (row?.status === 'Send') ? 'Sent' : row?.status, // Ensure this returns the status value
            cell: (row) => {
                return (
                    <div className='statusTab'><span className={(row?.status === 'Send') ? 'greenStatus' : (row?.status === 'Draft') ? 'draftStatus' : (row?.status === 'Schedule') ? 'scheduleStatus' : 'deletedStatus'}>{(row?.status === 'Send') ? 'Sent' : row?.status}</span></div>
                )
            },
            width: (columnWidths['status']  && parseInt(columnWidths['status'])) ? `${parseInt(columnWidths['status'])}px` : '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Recipients',
            selector: row => `${row?.sendTo?.plan === "All Subscribers" || row?.sendTo?.plan === "" || row?.sendTo?.plan === undefined ? "All Subscribers" : row?.sendTo?.plan }`,
            cell: (row) => {
                return (
                    <span className={`toSubject ${row?.sendTo?.slug === 'planOne' ? `pagePaidPlanLabel month` : row?.sendTo?.slug === "freePlan" ? 'pageFreePlanLabel' : "pageAllSubscriberLabel" }`}>{`${row?.sendTo?.plan === "All Subscribers" || row?.sendTo?.plan === "" || row?.sendTo?.plan === undefined ? "All Subscribers" : row?.sendTo?.plan }`}</span>
                )
            },
            sortable: true,
            paddingLeft: 0,
            width: columnWidths['sendTo.plan'],
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: columnWidths['sendTo.plan'], fontSize: "14px" },
        },
        {
            name: 'Last Updated',
            selector: row => formateDate(row?.updatedAt),
            sortable: true,
            paddingLeft: 0,
            width: '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '150px', fontSize: "14px" },
        },
        {
            name: 'Link',
            cell: (row) => {
                let pageUrl;
                if (row?.status === 'Send') {
                    pageUrl = row?.link;
                } else {
                    pageUrl = `https://reactadminportal.codeptsolutions.com/users/${id}/newsletters/previewnewsletter/${row?._id}`
                }
                return (
                    <div className='pagesTableLink'><Link target={'_blank'} to={`${pageUrl}`}><button className='pagesLinkButton'>
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.88252 9.44272L7.11748 10.2078C6.06116 11.2641 4.34855 11.2641 3.29223 10.2078C2.23592 9.15146 2.23592 7.43884 3.29223 6.38252L4.05728 5.61748M10.9427 6.38252L11.7078 5.61748C12.7641 4.56116 12.7641 2.84855 11.7078 1.79223C10.6515 0.735922 8.93884 0.735922 7.88252 1.79223L7.11748 2.55728M5.6066 7.89339L9.3934 4.10659" stroke="#344054" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round" />
                        </svg>
                        {row?.status === 'Send' ? 'Link' : 'Preview'}
                    </button></Link></div>
                )
            },
            paddingLeft: 0,
            width: (selectedFilterType.includes('Send') && selectedFilterType.includes('Draft') && selectedFilterType.includes('Deleted')) ? `${parseInt(columnWidths['status']) - 32}px` :
            (selectedFilterType.includes('Send') && selectedFilterType.includes('Draft')) ? `${parseInt(columnWidths['status']) - 14}px` :  
            (selectedFilterType.includes('Send') && selectedFilterType.includes('Deleted')) ? `${parseInt(columnWidths['status']) - 34}px` : 
            (selectedFilterType.includes('Send') && !selectedFilterType.includes('Deleted') && !selectedFilterType.includes('Draft')) ? `${parseInt(columnWidths['status']) - 30}px` : 
            ((selectedFilterType.includes('Draft')) && (((!selectedFilterType.includes('Send')) && (!selectedFilterType.includes('Deleted'))))) ? `${parseInt(columnWidths['status']) - 14}px` :
            ((selectedFilterType.includes('Deleted')) && (((!selectedFilterType.includes('Send')) && (!selectedFilterType.includes('Draft')))) || ((selectedFilterType.includes('Deleted') && selectedFilterType.includes('Draft')))) ? `${parseInt(columnWidths['status']) - 30}px` :
            `${parseInt(columnWidths['status']) - 14}px`,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <div className='pagesTableAction publicationAction' onClick={() => togglePopup(row)} ref={ref}>
                        <img src={more} alt="More" />
                    </div>

                    {openPopupitem && openPopupitem._id == row._id && (
                        <div className="table-data-dropdown">
                            <ul>
                                {
                                    row?.status === "Deleted" ? 
                                    <li onClick={() =>{handleRestore(row)}}>
                                        <span>
                                            Restore
                                        </span>
                                    </li> : 
                                    <li>
                                        <Link className="table-edit-action" to={`/users/${id}/newsletters/newsletter/${row._id}`} onClick={handleActionEvent} >
                                            <span>
                                                <img src={penIcon} alt="pen-icon" />
                                                Edit
                                            </span>
                                        </Link>
                                    </li>
                                }
                                <li>
                                    <span onClick={() => handleDeleteActionEvent( row ) } >
                                        {row?.status !== "Deleted" && <img src={Delete} alt="pen-icon" />}
                                        Delete
                                    </span>
                                </li>
                            </ul>
                        </div>
                    )}
                </>
            ),
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            // minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            headerStyle: { textAlign: 'right' },
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <>
            <section className="dashboard newsletter-list">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageTeam">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input type="text" id="pages_search" name="pages_search" className="inputFields" placeholder={'Search newsletters..'} value={searchInput} onChange={e => setSearchInput(e.target.value)} readOnly={readFields} onFocus={ () => setReadFields(false) } onBlur={ () => setReadFields(true) }  autocomplete="off" />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown newsletter-dropdown" ref={statusRef}>
                                <button className="selectFields" onClick={(e) => setFilterTypeSelect(!userFilterSelect)}>
                                    All Newsletters{" "}
                                    {selectedFilterType.length > 0 ? `(${selectedFilterType.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userFilterSelect === true) ? <CheckboxActionDropdown options={filterType} selectedOptions={selectedFilterType} changeOption={setSelectedFilterType} /> : ''
                                }
                            </div>
                        </div>

                        <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>
                            <div className="add-new-member">
                                <Link to={`/users/${id}/newsletters/newsletter/`}>
                                    <button className="form-btn primary-button button">
                                        <img src={plus} alt="plus" />
                                        New Newslatter
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`pagesTable dataTable-list ${(isLoadingData) ? 'loading-list' : ''}`}>
                        <DataTableList
                            columns={columns}
                            data={originalData}
                            onSelectedRowsChange={({ selectedRows }) => {
                                const selectedIds = selectedRows.map((row) => row._id);
                                setSelectedRows(selectedIds);
                                setIsAllSelected(selectedIds.length === originalData.length);
                            }}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRowsComponent="input"
                            selectAllRows={isAllSelected}
                            onSelectAllChange={handleMasterCheckboxChange}
                            clearSelectedRows={toggleSelect}
                            selectableRows={true}
                            noDataComponent={<NoResultNewsletter />}
                        />
                    </div>
                </div>
            </section>

            <DeleteNewsletterModal
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedRows}                
                onClose={onClose}
                setOpen={setOpen}
                refrence={popupRef}
                fetchData={fetchData}
                originalData={originalData}
                setOpenNotify={setOpenNotify}
                setMultiDeteleNotify={setMultiDeteleNotify}
                setSelectedNewsLenght={setSelectedNewsLenght}
                setSelectedItems={setSelectedRows}
                setToggleSelect={setToggleSelect}
                toggleSelect={toggleSelect}  
            />

            { openNotify && 
                <Notification title={`The newsletter was successfully deleted!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { multiDeteleNotify && 
                <Notification title={`${selectedNewsLenght} newsletters were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeteleNotify} />
            }

        </>
    );
}
export default Newsletter;