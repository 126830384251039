import { NodeViewWrapper } from "@tiptap/react"
import { useEffect, useRef, useState } from "react";
import UploadMediaPopup from "../../../../../Pages/Users/UploadMediaPopup";

export const ProductBlockView = (props) => {
    const { editor, getPos, node } = props;
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [title, setTitle] = useState(node?.attrs?.title || '');
    const [description, setDescription] = useState(node?.attrs?.description || '');

    const [editAttributes, setEditAttributes] = useState({
        image: node?.attrs?.image,
        title: node?.attrs?.title,
        description: node?.attrs?.description,
        showRating: node?.attrs?.showRating,
        rating: node?.attrs?.rating,
        showButton: node?.attrs?.showButton,
        buttonText: node?.attrs?.buttonText,
        buttonUrl: node?.attrs?.buttonUrl,
    });

    useEffect(() => {
        setEditAttributes({
            image: node?.attrs?.image,
            title: node?.attrs?.title,
            description: node?.attrs?.description,
            showRating: node?.attrs?.showRating,
            rating: node?.attrs?.rating,
            showButton: node?.attrs?.showButton,
            buttonText: node?.attrs?.buttonText,
            buttonUrl: node?.attrs?.buttonUrl,
        })
    }, [node])

    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
    const [selectedProductImage, setSelectedProductImage] = useState('');
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);

    const deletImage = () => {
        setEditAttributes((prev) => {
            const newAttrs = { ...prev, image: "" };
            if (editor) {
                setSelectedProductImage('')
                editor.chain().focus().updateAttributes("product", newAttrs).run();
            }
            return newAttrs;
        });
    }; 
    useEffect(() => {
        if (selectedProductImage !== '') {
            if (uploadMediaPopupFor === 'editor-image') {
                setSelectMediaLoading(false);
                setShowUploadMediaPopup(false);
                setUploadMediaPopupFor('');

                editor.chain().focus().setProduct({ image: selectedProductImage?.url, producatimageAlt: selectedProductImage?.alt, productId: selectedProductImage?._id, productKey: selectedProductImage?.key, title: editAttributes?.title, description: editAttributes?.description, showRating: editAttributes?.showRating, rating: editAttributes?.rating, showButton: editAttributes?.showButton, buttonText: editAttributes?.buttonText, buttonUrl: editAttributes?.buttonUrl }).run();
            }
        }
    }, [selectedProductImage]);

    const handleTitleBlur = () => {
        if (title !== node.attrs.title) {
            editor.commands.updateAttributes('product', { title });
        }
    };
    const handleDescriptionBlur = () => {        
        if (description !== node.attrs.description) {
            editor.commands.updateAttributes('product', { description });
        }
    };

    const handleCaptionClick = () => {
        const nodePosition = getPos(); 
        editor.commands.setNodeSelection(nodePosition);
    };

    return (
        <>
            <NodeViewWrapper data-type='product'>                
                <div className="product-node-wrapper" 
                      data-image= {node.attrs.image}
                            data-productId= {node.attrs.productId}
                            data-productKey= {node.attrs.productKey}
                            data-caption= {node.attrs.caption}
                            data-imageAlt= {node.attrs.producatimageAlt}
                            data-title= {node.attrs.title}
                            data-description= {node.attrs.description}
                            data-showRating= {node.attrs.showRating}
                            data-rating= {node.attrs.rating}
                            data-showButton= {node.attrs.showButton}
                            data-buttonText= {node.attrs.buttonText}
                            data-buttonUrl= {node.attrs.buttonUrl}
                >
                    {
                        (node.attrs.image !== "" && node.attrs.image !== undefined) ?
                            <div className='image-block gallery-image' >
                                {node.attrs.image ? (
                                    <div className='image-preview'
                                        style={{ width: '100%' }}>
                                        {
                                            node.attrs.buttonUrl !== "" && node.attrs.buttonUrl !== undefined ?
                                                <a href={node.attrs.buttonUrl} target='_blank' style={{ pointerEvents: "none" }}>
                                                    <div className='delete-item-block'>
                                                        <div className='delete-item' onClick={() => { deletImage() }}>
                                                            <button className='delete-button'>
                                                                <svg width="20" height="20" stroke='currentColor' viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.33333 3.4974V3.03073C9.33333 2.37733 9.33333 2.05064 9.20617 1.80107C9.09432 1.58155 8.91584 1.40307 8.69632 1.29122C8.44676 1.16406 8.12006 1.16406 7.46667 1.16406H6.53333C5.87994 1.16406 5.55324 1.16406 5.30368 1.29122C5.08416 1.40307 4.90568 1.58155 4.79383 1.80107C4.66667 2.05064 4.66667 2.37733 4.66667 3.03073V3.4974M5.83333 6.70573V9.6224M8.16667 6.70573V9.6224M1.75 3.4974H12.25M11.0833 3.4974V10.0307C11.0833 11.0108 11.0833 11.5009 10.8926 11.8752C10.7248 12.2045 10.4571 12.4722 10.1278 12.64C9.75347 12.8307 9.26342 12.8307 8.28333 12.8307H5.71667C4.73657 12.8307 4.24653 12.8307 3.87218 12.64C3.5429 12.4722 3.27518 12.2045 3.10741 11.8752C2.91667 11.5009 2.91667 11.0108 2.91667 10.0307V3.4974" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <img src={node.attrs.image} alt="Uploaded" />
                                                </a> :
                                                <>
                                                    <div className='delete-item-block' onClick={() => { deletImage() }}>
                                                        <div className='delete-item'>
                                                            <button className='delete-button'>
                                                                <svg width="20" height="20" stroke='currentColor' viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9.33333 3.4974V3.03073C9.33333 2.37733 9.33333 2.05064 9.20617 1.80107C9.09432 1.58155 8.91584 1.40307 8.69632 1.29122C8.44676 1.16406 8.12006 1.16406 7.46667 1.16406H6.53333C5.87994 1.16406 5.55324 1.16406 5.30368 1.29122C5.08416 1.40307 4.90568 1.58155 4.79383 1.80107C4.66667 2.05064 4.66667 2.37733 4.66667 3.03073V3.4974M5.83333 6.70573V9.6224M8.16667 6.70573V9.6224M1.75 3.4974H12.25M11.0833 3.4974V10.0307C11.0833 11.0108 11.0833 11.5009 10.8926 11.8752C10.7248 12.2045 10.4571 12.4722 10.1278 12.64C9.75347 12.8307 9.26342 12.8307 8.28333 12.8307H5.71667C4.73657 12.8307 4.24653 12.8307 3.87218 12.64C3.5429 12.4722 3.27518 12.2045 3.10741 11.8752C2.91667 11.5009 2.91667 11.0108 2.91667 10.0307V3.4974" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <img src={node.attrs.image} alt="Uploaded" />
                                                </>
                                        }
                                    </div>
                                ) : (
                                    <p>No image available</p>
                                )}
                            </div>
                            :

                            <div data-type="image" className={`image-node-wrapper`} data-drag-handle>
                                <div>
                                    <div className={`newpageLeftRow textBlock uploadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                                        <div
                                            className={isImageDragging ? 'dragging fileUploadBox' : 'fileUploadBox'}
                                            onClick={() => {
                                                setShowUploadMediaPopup(true);
                                                setUploadMediaPopupFor('editor-image');
                                                setUploadMediaPopupTab('upload');
                                            }}
                                        >

                                            {loadingFiles ?
                                                <div className="progress-wrap">
                                                    <div className="progress">
                                                        <div className="color"></div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="uploadFileBlock">
                                                    <div className="featureImgMiddleContent">
                                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='fileUploadCover_Img'>
                                                            <g clipPath="url(#clip0_4592_9769)">
                                                                <path d="M14.175 17.375H6.06495C5.53486 17.375 5.26982 17.375 5.14709 17.2702C5.04059 17.1792 4.98408 17.0428 4.99507 16.9032C5.00773 16.7423 5.19515 16.5549 5.56998 16.18L13.0101 8.73995C13.3566 8.39344 13.5298 8.22018 13.7296 8.15526C13.9053 8.09816 14.0947 8.09816 14.2704 8.15526C14.4702 8.22018 14.6434 8.39343 14.9899 8.73995L18.375 12.125V13.175M14.175 17.375C15.6451 17.375 16.3802 17.375 16.9417 17.0889C17.4357 16.8372 17.8372 16.4357 18.0889 15.9417C18.375 15.3802 18.375 14.6451 18.375 13.175M14.175 17.375H6.825C5.35486 17.375 4.61979 17.375 4.05827 17.0889C3.56435 16.8372 3.16278 16.4356 2.91111 15.9417C2.625 15.3802 2.625 14.6451 2.625 13.175V5.825C2.625 4.35486 2.625 3.61979 2.91111 3.05827C3.16278 2.56435 3.56435 2.16278 4.05827 1.91111C4.61979 1.625 5.35486 1.625 6.825 1.625H14.175C15.6451 1.625 16.3802 1.625 16.9417 1.91111C17.4356 2.16278 17.8372 2.56435 18.0889 3.05827C18.375 3.61979 18.375 4.35486 18.375 5.825V13.175M9.1875 6.4375C9.1875 7.404 8.404 8.1875 7.4375 8.1875C6.471 8.1875 5.6875 7.404 5.6875 6.4375C5.6875 5.471 6.471 4.6875 7.4375 4.6875C8.404 4.6875 9.1875 5.471 9.1875 6.4375Z" stroke="#475467" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_4592_9769">
                                                                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <p className="newPageImageUploadText">
                                                            <span>Upload</span> or <span
                                                                onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('editor-image'); setUploadMediaPopupTab('media'); }}
                                                            >Open Media Library</span>
                                                            <br />
                                                            JPG, PNG (max. 128MB)
                                                        </p>
                                                        {errorMessages && errorMessages.length > 0 && (
                                                            <div className="errorMessages">
                                                                {errorMessages.map((errorMessage, index) => (
                                                                    <p key={index} className="error-message">
                                                                        {errorMessage}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                    }
                    <div className='product-content'>
                        <div className="product-title-wrapper">
                            <h2 
                                className='product-title'
                                contentEditable={true} 
                                suppressContentEditableWarning 
                                onInput={(e) => setTitle(e.currentTarget.textContent)} 
                                onBlur={handleTitleBlur} onClick={handleCaptionClick}
                            >
                                {node.attrs.title}
                            </h2>
                            {(editAttributes?.showRating).toString() == 'true' &&
                                <div className='rating'>
                                    {
                                        <>
                                            <span className={`rate ${1 <= editAttributes.rating ? 'highlighted' : ''}`} style={{ cursor: 'pointer' }} >
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                                    <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z" />
                                                </svg>
                                            </span>
                                            <span className={`rate ${2 <= editAttributes.rating ? 'highlighted' : ''}`} style={{ cursor: 'pointer' }} >
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                                    <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z" />
                                                </svg>
                                            </span>
                                            <span className={`rate ${3 <= editAttributes.rating ? 'highlighted' : ''}`} style={{ cursor: 'pointer' }} >
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                                    <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z" />
                                                </svg>
                                            </span>
                                            <span className={`rate ${4 <= editAttributes.rating ? 'highlighted' : ''}`} style={{ cursor: 'pointer' }} >
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                                    <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z" />
                                                </svg>
                                            </span>
                                            <span className={`rate ${5 <= editAttributes.rating ? 'highlighted' : ''}`} style={{ cursor: 'pointer' }} >
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F2AE40">
                                                    <path d="M17.75 6.69221L11.5925 5.95272L8.99671 0.25L6.40096 5.95272L0.25 6.69221L4.7975 10.9626L3.59491 17.125L8.99671 14.0538L14.4051 17.125L13.2025 10.9626L17.75 6.69221Z" />
                                                </svg>
                                            </span>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                        <p 
                            className='product-description' 
                            contentEditable={true} 
                            suppressContentEditableWarning 
                            onInput={(e) => setDescription(e.currentTarget.textContent)} 
                            onBlur={handleDescriptionBlur}
                            onClick={handleCaptionClick}
                        >
                            {node.attrs.description}
                        </p>
                    </div>
                    {(editAttributes?.showButton).toString() == 'true' &&
                        <>

                            {
                                (editAttributes?.buttonUrl !== "" && editAttributes?.buttonUrl !== undefined) ?
                                    <a href={editAttributes?.buttonUrl} target="_blank">
                                        <div className='button-element product-button'>
                                            <button >{editAttributes?.buttonText}</button>
                                        </div>
                                    </a>
                                    :

                                    <div className='button-element product-button'>
                                        <button >{editAttributes?.buttonText}</button>
                                    </div>
                            }
                        </>
                    }
                </div>
            </NodeViewWrapper>
            {showUploadMediaPopup &&
                <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedProductImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
            }
        </>
    )
}