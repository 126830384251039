import React from 'react'
import { Helmet } from 'react-helmet';
import TeamMemberTable from "./TeamMemberTable";

const TeamList = () => {
    return (
        <>
            <Helmet>
                <title>Team | Persone</title>
            </Helmet>
            <div className="dashboard-top-left">
                <h2 className="dashboard-top-text">
                    Team
                </h2>
                <p className="dashboard-top-subtext">
                    Manage your team members, including support staff and profile assistants, with tools to view, edit, and oversee their roles and activities.
                </p>
            </div>
            <TeamMemberTable />
        </>
    )
};

export default TeamList;


