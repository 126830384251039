import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import iconDropDown from "../../images/icondropdown.svg";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import search from "../../images/search.svg";
import NoResultSubscriber from "./NoResultSubscriber";
import { DataTableList } from "../../components/DataTable/DataTableList";

export const Subscriber = (props) => {
    const { id } = useParams();
    const publicationStatusRef = useRef();
    const publicationCatRef = useRef();

    props.setActivePage("Subscriber");
    const [searchInput, setSearchInput] = useState("");
    const [userStatusSelect, setPublicationStatusSelect] = useState(false);
    const [userCategorySelect, setPublicationCategorySelect] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSubscriberStatus, setSelectedSubscriberStatus] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [readFields, setReadFields] = useState(true);

    const [originalData, setOriginalData] = useState([]);
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [columnWidths, setColumnWidths] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const statusList = ['Free Subscribers', 'Paid Subscribers', 'Monthly Subscribers', 'Yearly Subscribers', 'Trial subscribers'];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (publicationStatusRef.current && !publicationStatusRef.current.contains(event.target)) {
                setPublicationStatusSelect(false);
            }

            if (publicationCatRef.current && !publicationCatRef.current.contains(event.target)) {
                setPublicationCategorySelect(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const abortControllerRef = useRef(null);
    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        try {
            setIsLoading(true)
            setIsLoadingData(true);
            let response;
                if (search === "") {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${id}?page=${currentPage}&filterSubscriber=${selectedSubscriberStatus}&search=${search}&planName=${selectedCategory}`);
                } else {

                    response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${id}?page=${currentPage}&filterSubscriber=${selectedSubscriberStatus}&search=${search}&planName=${selectedCategory}`, {
                        signal: abortControllerRef.current.signal
                    });
                }
            if(response?.data?.result <= 0){
                setIsLoadingData(false)
            }
            setOriginalData(response?.data?.result || []);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error(error, "error");
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            setIsLoadingData(true);
            try {
                setIsLoading(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriber/list/${id}?planName=${selectedCategory}`);
                const value = response?.data?.planName?.map((item) =>{
                    return item?.planName
                })
                if(response?.data?.result <= 0){
                    setIsLoadingData(false)
                }
                setCategoryList(value || [])
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.error(error, "error");
            }
        };
        fetchData();
    }, [id]);
    useEffect(() => {
        fetchData();
    }, [id,searchInput,selectedCategory,currentPage,selectedSubscriberStatus]);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedSubscriberStatus,selectedCategory]);
    useEffect(() => {
        searchPage();
    }, [searchInput, originalData, selectedSubscriberStatus]);

    function searchPage() {
        const input = searchInput.toLowerCase();
        const filteredData = input === '' 
          ? originalData 
          : originalData.filter(element => element?.email?.toLowerCase().startsWith(input));
        setPages(filteredData);
    }
    

    const calculateColumnWidths = async () => {
        return new Promise((resolve) => {
            const tempWidths = originalData?.reduce((acc, row) => {
                Object.keys(row).forEach((key) => {
                    const dataValue = row[key].toString();
                    const title = row[key];

                    // Compare title length with data content length and take the max

                    let maxContentLength = 0;

                    if (key === 'plan') {
                        let planText = 'Free Plan';

                        if (row['planName'] !== undefined && row['planName'] !== '') {
                            planText = row['planName'];
                        }

                        if( row['plan'] === "planOne" ) {
                            if( row['interval'] === "year" ) {
                                maxContentLength = Math.max(planText.length + 4.5, title.length);
                            } else {
                                maxContentLength = Math.max(planText.length + 4, title.length);
                            }
                        } else {
                            maxContentLength = Math.max(planText.length, title.length);
                        }
                    } else if (key === 'emailVerification') {
                        // Handling the Status column
                        const status = (row.emailVerification === true && row?.trialData?.isTrial !== true) ? 'Subscribed' :
                                    (row.emailVerification === true && row?.trialData?.isTrial === true) ? 'Trial' : 'Pending';
                        maxContentLength = Math.max(status.length + 1.5);
                    } else {
                        maxContentLength = Math.max(dataValue.length, title.length);
                    }

                    if (!acc[key] || maxContentLength > acc[key]) {
                        acc[key] = maxContentLength;
                    }
                });
                return acc;
            }, {});
            if(tempWidths !== undefined){
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    if (key === 'createdAt' || key === 'updatedAt') {
                    } else {
                        acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    }
                    return acc;
                }, {});
                setColumnWidths(widthMap); // Set the calculated column widths
            }
            resolve(); // Resolve the promise once widths are set
        });    
    };

    useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        } 
    }, [originalData]);
    
    useEffect(() => {
        if (Object.keys(columnWidths).length > 0) {
            setTimeout(() => {
                setIsLoadingData(false);
            }, 1000);  
        }
    }, [columnWidths]);

    function formateDate(date) {
        var createdAtDate = new Date(date);

        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var formattedDate = createdAtDate.toLocaleDateString('en-US', options);
        return formattedDate
    }
    
    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.email.toString().toLowerCase();
        const titleB = rowB?.email.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const columns = [
        {
            name: 'Email',
            selector: (row) => row?.email,
            cell: (row) => {
                return (
                    <span className='emailLink'>{row?.email}</span>
                )
            },
            sortable: true,
            minWidth: columnWidths['email'] && (parseInt(columnWidths['email']) + 50 > 300) ? '300px' : `${parseInt(columnWidths['email']) + 50}px`,  
            sortFunction: customTitleSort,
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
        },
        {
            name: 'Status',
            selector: (row) => (row.emailVerification === true && row?.trialData?.isTrial !== true ) ? 'Subscribed': (row.emailVerification === true && row?.trialData?.isTrial === true ) ? 'Trial' : 'Pending',
            cell: (row) => {
                return (
                    <p className={`verifyStatus ${(row.emailVerification === true && row?.trialData?.isTrial !== true) ? 'verifySubscribe' : (row.emailVerification === true && row?.trialData?.isTrial === true) ? 'verifyTrial' : 'verifyPending'}`}>{(row.emailVerification === true && row?.trialData?.isTrial !== true ) ? 'Subscribed': (row.emailVerification === true && row?.trialData?.isTrial === true ) ? 'Trial' : 'Pending'}</p>
                )
            },
            width: columnWidths['emailVerification'] || '130px',
            style: { width: columnWidths['emailVerification'] || '130px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Plan',
            selector: row => `${row?.planName || 'Free Plan'} ${((row?.plan === "planOne" && row?.interval === "month" && row?.trialData?.isTrial !== true) && `(Monthly)`) } ${((row?.plan === "planOne" && row?.interval === "year" && row?.trialData?.isTrial !== true) && `(Yearly)`) }`,
            cell: (row) => {
                return (
                    <p><span className={`${row?.plan !== 'freePlan' ? `pagePaidPlanLabel` : 'pageFreePlanLabel'} verifyStatus ${row?.interval}`}>{row?.planName || 'Free Plan'} {((row?.plan === "planOne" && row?.interval === "month" && row?.trialData?.isTrial !== true) && `(Monthly)`) } {((row?.plan === "planOne" && row?.interval === "year" && row?.trialData?.isTrial !== true) && `(Yearly)`) }</span></p>
                )
            },
            sortable: true,
            paddingLeft: 0,
            width: columnWidths['plan'] || '130px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", width: columnWidths['plan'] || '130px', fontSize: "14px" },
        },
        {
            name: 'Date',
            selector: row => formateDate(row?.createdAt),
            sortable: true,
            paddingLeft: 0,
            width: '110px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px", minWidth: '110px', fontSize: "14px" },
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };
    return (
        <>
            <section className="dashboard subscriber-list">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageTeam">
                        <div className="pageSearchTop pageSearchTopMobile">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search subscriber..."}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields}
                                    onFocus={() => setReadFields(false)}
                                    onBlur={() => setReadFields(true)}
                                />
                                <img src={search} alt="Search" />
                            </div>
                            {
                                props.billingDetails?.subscriptions?.plan !== "free_plan" &&
                            <div className="userrole-dropdown userrole-mobiledropdown" ref={publicationStatusRef}>
                                <button className="selectFields subscriberFillter" onClick={(e) => setPublicationStatusSelect(!userStatusSelect)}>
                                    All Subscriber{" "}
                                    {selectedSubscriberStatus.length > 0 ? `(${selectedSubscriberStatus.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userStatusSelect === true) ? <CheckboxActionDropdown options={statusList} selectedOptions={selectedSubscriberStatus} changeOption={setSelectedSubscriberStatus} /> : ''
                                }
                            </div>
                            }

                            <div className="category-dropdown userrole-dropdown userrole-mobiledropdown" ref={publicationCatRef}>
                                <button className="selectFields subscriberPlan" onClick={(e) => setPublicationCategorySelect(!userCategorySelect)}>
                                    All Plan{" "}
                                    {selectedCategory.length > 0 ? `(${selectedCategory.length})` : ""}
                                    <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userCategorySelect === true) ? <CheckboxActionDropdown options={categoryList} selectedOptions={selectedCategory} changeOption={setSelectedCategory} /> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`pagesTable dataTable-list ${(isLoadingData) ? 'loading-list' : ''}`}>
                        <DataTableList
                            columns={columns}
                            data={originalData}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                            onChangePage={setCurrentPage}
                            paginationTotalRows={totalPage}
                            currentPage={currentPage}
                            customStyles={customStyles}
                            selectableRows={false}
                            noDataComponent={<NoResultSubscriber />}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
